import { Group, Pagination, Select, Text } from "@mantine/core";
import { useLayoutEffect, useRef, useState } from "react";

interface PaginationControlProps {
  activePage: number;
  setActivePage: (page: number) => void;
  pageSize: number;
  setPageSize: (item: number) => void;
  rowsNumber: number;
}

const PaginationControl = ({
  activePage,
  setActivePage,
  pageSize,
  setPageSize,
  rowsNumber,
}: PaginationControlProps) => {
  const startIndex = (activePage - 1) * pageSize;
  const endIndex =
    pageSize !== 0 ? Math.min(rowsNumber, startIndex + pageSize) : rowsNumber;

  const parentRef = useRef<HTMLInputElement>(null);

  const [parentWidth, setParentWidth] = useState(0);

  useLayoutEffect(() => {
    if (parentRef.current) {
      setParentWidth(parentRef.current.offsetWidth);
    }
  }, [parentRef]);

  const narrowView = parentWidth < 700;

  return (
    <Group position="apart" p={narrowView ? 0 : 4} ref={parentRef}>
      {rowsNumber > 0 ? (
        <Text size="sm" color="gray.7" align="left">
          {!narrowView && "Showing"} {startIndex + 1} - {endIndex} of{" "}
          {rowsNumber}
        </Text>
      ) : (
        <Text size="sm" align="left">
          No results
        </Text>
      )}

      <Pagination
        value={activePage}
        onChange={setActivePage}
        total={
          pageSize !== 0 && rowsNumber > 0
            ? rowsNumber / pageSize + (rowsNumber % pageSize > 0 ? 1 : 0)
            : 1
        }
        size="sm"
        color="blue.6"
        radius="xl"
        spacing={narrowView ? "xs" : "md"}
        styles={(theme) => ({
          dots: {
            "& > *": {
              color: theme.colors.gray[5],
            },
          },
          control: {
            "&": {
              borderColor: theme.colors.white,
              borderWidth: "0px",
              fontWeight: 400,
              color: theme.colors.gray[7],
            },
            "&[data-active]": {
              backgroundColor: theme.colors.blue[0],
              color: theme.colors.blue[7],
              fontWeight: 600,
            },
            "&[data-active]&:hover": {
              backgroundColor: theme.colors.blue[1],
              color: theme.colors.blue[7],
            },
          },
        })}
      />
      <Select
        color="gray.7"
        variant="unstyled"
        size="xs"
        style={{ width: "105px" }}
        styles={(theme) => ({
          input: {
            // applies styles to selected item
            "&": {
              color:
                theme.colorScheme === "dark"
                  ? theme.white
                  : theme.colors.gray[7],
            },

            // applies styles to hovered item (with mouse or keyboard)
            "&[data-hovered]": {},
          },
        })}
        value={pageSize.toString()}
        onChange={(event) => {
          setActivePage(1);
          setPageSize(parseInt(event!, 10));
        }}
        radius="xl"
        data={[
          { value: "10", label: "10 per page" },
          { value: "25", label: "25 per page" },
          { value: "50", label: "50 per page" },
          { value: "100", label: "100 per page" },
          { value: "0", label: "Show all" },
        ]}
      />
    </Group>
  );
};

export default PaginationControl;
