import {
  Badge,
  Center,
  Container,
  createStyles,
  Divider,
  Group,
  HoverCard,
  MultiSelect,
  Table,
  Text,
  TextInput,
  UnstyledButton,
} from "@mantine/core";
import { useDebouncedState } from "@mantine/hooks";
import {
  IconChevronDown,
  IconChevronUp,
  IconHash,
  IconInfoCircle,
  IconSearch,
  IconSelector,
} from "@tabler/icons";
import React, { useState } from "react";
import { SalesforceIntegrationsAndActivityRecord } from "../../api/generated";
import { usePaginationStore } from "../../state/store";
import PaginationControl from "../common/PaginationControl";

const useStyles = createStyles((theme) => ({
  rowSelected: {
    backgroundColor:
      theme.colorScheme === "dark"
        ? theme.fn.rgba(theme.colors[theme.primaryColor][7], 0.2)
        : theme.colors[theme.primaryColor][0],
  },

  tableHeader: {
    backgroundColor:
      theme.colorScheme === "dark"
        ? theme.fn.rgba(theme.colors["gray"][8], 0.2)
        : theme.colors["gray"][0],
  },

  th: {
    padding: "0 !important",
  },

  control: {
    width: "100%",
    padding: `${theme.spacing.xs} ${theme.spacing.md}`,

    "&:hover": {
      backgroundColor:
        theme.colorScheme === "dark"
          ? theme.colors.dark[6]
          : theme.colors.gray[0],
    },
  },

  icon: {
    width: 21,
    height: 21,
  },
}));

interface ThProps {
  children: React.ReactNode;
  reversed: boolean;
  sorted: boolean;
  style?: React.CSSProperties;
  description?: React.ReactNode;
  onSort(): void;
  position?: "left" | "right" | "center" | "apart";
  pl?: number;
  pr?: number;
}

function Th({
  children,
  reversed,
  sorted,
  onSort,
  style,
  description,
  position = "left",
  pl = 10,
  pr = 10,
}: ThProps) {
  const { classes } = useStyles();
  const Icon = sorted
    ? reversed
      ? IconChevronUp
      : IconChevronDown
    : IconSelector;
  return (
    <th style={style} className={classes.th}>
      <UnstyledButton
        onClick={onSort}
        className={classes.control}
        pl={pl}
        pr={pr}
      >
        <Group spacing={4} position="apart">
          <Group spacing={4}>
            <Text
              weight={500}
              size="xs"
              style={{ textOverflow: "ellipsis", overflow: "hidden" }}
              lineClamp={1}
            >
              {children}
            </Text>
            {description && (
              <HoverCard
                width={360}
                shadow="xl"
                withArrow
                openDelay={100}
                closeDelay={100}
                position="bottom"
                offset={20}
              >
                <HoverCard.Target>
                  <Center className={classes.icon}>
                    <IconInfoCircle size={14} stroke={1} />
                  </Center>
                </HoverCard.Target>
                <HoverCard.Dropdown>{description}</HoverCard.Dropdown>
              </HoverCard>
            )}
          </Group>
          <Center className={classes.icon}>
            <Icon size={14} stroke={1} />
          </Center>
        </Group>
      </UnstyledButton>
    </th>
  );
}

interface TableDataFlowSalesforceInstanceProps {
  instance: SalesforceIntegrationsAndActivityRecord;
}

interface TableDataFlowSalesforceInstanceRecord {
  field: string;
  classification: string;
  integrations: string;
}

const TableDataFlowSalesforceInstance = (
  props: TableDataFlowSalesforceInstanceProps
) => {
  const { instance } = props;

  const { classes, cx } = useStyles();

  const [sortBy, setSortBy] = useState<
    "field" | "classification" | "integrations" | null
  >("classification");
  const [reverseSortDirection, setReverseSortDirection] = useState(true);

  const [filterConditions, setFilterConditions] = useState<string[]>([
    "classification-PII",
    "classification-PHI",
    "classification-PCI",
  ]);
  const [searchCondition, setSearchCondition] = useDebouncedState<string>(
    "",
    200
  );

  const setSorting = (field: "field" | "classification" | "integrations") => {
    const reversed = field === sortBy ? !reverseSortDirection : false;
    setReverseSortDirection(reversed);
    setSortBy(field);
  };

  const { pageSize, setPageSize } = usePaginationStore.get("instance-flow")!(
    (state) => ({
      pageSize: state.pageSize,
      setPageSize: state.setPageSize,
    })
  );

  const [activePage, setPage] = useState(1);

  let searchConditionNormalized: string | null = null;
  if (searchCondition) {
    // allow for "Entity Field" search by replacing spaces with "::"
    // optimize search speed by converting searchCondition to lowercase only once
    searchConditionNormalized = searchCondition
      .toLowerCase()
      .replace(" ", "::");
  }

  const tableRows = instance
    .classifiedFields!.filter((r) => {
      // filter out any malformed records
      if (r.split("::").length < 3) {
        return false;
      }
      // filter out fields with empty names (SF has such fields for whatever reason)
      if (r.split("::")[1].trim().length === 0) {
        return false;
      }
      let searchOk = true;
      if (searchConditionNormalized !== null) {
        searchOk = r?.toLowerCase().includes(searchConditionNormalized);
      }
      if (
        filterConditions.length === 0 ||
        (filterConditions.length !== 0 &&
          (!filterConditions.find((c) => c.includes("classification-")) ||
            filterConditions.includes(`classification-${r.split("::")[2]}`)))
      ) {
        return searchOk;
      } else {
        return false;
      }
    })
    .map((r) => {
      // map into TableDataFlowSalesforceInstanceRecord
      const split = r.split("::");
      const field = split[0] + " " + split[1];
      const classification = split[2];
      const integrations = split[4];
      return {
        field,
        classification,
        integrations,
      } as TableDataFlowSalesforceInstanceRecord;
    })
    .sort((a, b) => {
      if (!sortBy) {
        return (
          b.classification!.localeCompare(a.classification!, "en") *
          (reverseSortDirection ? -1 : 1)
        );
      }

      switch (sortBy) {
        case "field":
          return (
            b!.field!.localeCompare(a!.field!, "en") *
            (reverseSortDirection ? -1 : 1)
          );
        case "integrations":
          return (
            b!.integrations!.localeCompare(a!.integrations!, "en") *
            (reverseSortDirection ? -1 : 1)
          );
        case "classification":
          return (
            b!.classification!.localeCompare(a!.classification!, "en") *
            (reverseSortDirection ? -1 : 1)
          );

        default:
          return (
            b!.classification!.localeCompare(a!.classification!, "en") *
            (reverseSortDirection ? -1 : 1)
          );
      }
    });

  const rowsFilteredLength = tableRows.length;

  const pageSizeAdjusted = pageSize !== 0 ? pageSize! : rowsFilteredLength;

  const rowsToDisplay = tableRows
    .slice((activePage - 1) * pageSizeAdjusted, activePage * pageSizeAdjusted)
    .map((r) => {
      const record = r!;
      return (
        <tr key={record.field} style={{ height: "30px" }}>
          <td style={{ textAlign: "left", paddingLeft: 10 }}>
            <div style={{ width: "calc(100% - 20px)" }}>
              <Text
                ml="xs"
                size="xs"
                style={{ textOverflow: "ellipsis", overflow: "hidden" }}
                lineClamp={4}
              >
                {record.field}
              </Text>
            </div>
          </td>

          <td style={{ textAlign: "left", paddingLeft: 10, paddingRight: 20 }}>
            <Badge
              radius="xl"
              size="md"
              color={
                record.classification === "PHI"
                  ? "green.6"
                  : record.classification === "PII"
                  ? "pink.6"
                  : record.classification === "Sensitive"
                  ? "grape.6"
                  : record.classification === "PCI"
                  ? "red.6"
                  : "gray.6"
              }
            >
              {record.classification}
            </Badge>
          </td>

          <td align="left" style={{ paddingLeft: 10 }}>
            <Text size="xs">{record.integrations}</Text>
          </td>
        </tr>
      );
    });

  return (
    <>
      <Group py="xl" position="apart">
        <TextInput
          placeholder="Search"
          style={{ width: "260px" }}
          onChange={(event) => {
            setSearchCondition(event.currentTarget.value);
            setPage(1);
          }}
          radius="xl"
          icon={<IconSearch size={16} stroke={1} />}
        />
        <MultiSelect
          style={{
            minWidth: "260px",
            maxWidth: "360px",
          }}
          data={[
            {
              value: "classification-PHI",
              label: "PHI",
              group: "Classification",
            },
            {
              value: "classification-PII",
              label: "PII",
              group: "Classification",
            },
            {
              value: "classification-Sensitive",
              label: "Sensitive",
              group: "Classification",
            },
            {
              value: "classification-PCI",
              label: "PCI",
              group: "Classification",
            },
            {
              value: "classification-Unclassified",
              label: "Unclassified",
              group: "Classification",
            },
          ]}
          placeholder="Search"
          defaultValue={[
            "classification-PII",
            "classification-PHI",
            "classification-PCI",
          ]}
          // clearButtonLabel="Clear selection"
          clearable
          maxDropdownHeight={600}
          onChange={(value) => {
            setFilterConditions(value);
            setPage(1);
          }}
          radius="xl"
          icon={<IconHash size={16} stroke={1} />}
        />
      </Group>

      <Table
        verticalSpacing="xs"
        horizontalSpacing={0}
        fontSize="xs"
        style={{ tableLayout: "fixed" }}
      >
        <thead>
          <tr className={cx({ [classes.tableHeader]: true })}>
            <Th
              sorted={sortBy === "field"}
              reversed={reverseSortDirection}
              onSort={() => setSorting("field")}
              style={{ textAlign: "left", minWidth: 180 }}
              pl={20}
            >
              Data Field
            </Th>

            <Th
              sorted={sortBy === "classification"}
              reversed={reverseSortDirection}
              onSort={() => setSorting("classification")}
              style={{ textAlign: "left", width: 140 }}
            >
              Classification
            </Th>

            <Th
              sorted={sortBy === "integrations"}
              reversed={reverseSortDirection}
              onSort={() => setSorting("integrations")}
              style={{ textAlign: "left", width: 200 }}
            >
              Integrations
            </Th>
          </tr>
        </thead>
        <tbody>{rowsToDisplay}</tbody>
      </Table>
      <Divider />
      <Container pl="xl" pr="lg" pt="lg" pb="lg" fluid>
        <PaginationControl
          activePage={activePage}
          setActivePage={setPage}
          pageSize={pageSize}
          setPageSize={setPageSize}
          rowsNumber={rowsFilteredLength}
        />
      </Container>
    </>
  );
};

export default TableDataFlowSalesforceInstance;
