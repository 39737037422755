import {
  Avatar,
  Badge,
  Container,
  Divider,
  Group,
  Loader,
  MantineTheme,
  Stack,
  Table,
  Text,
  Tooltip,
  UnstyledButton,
  useMantineTheme,
} from "@mantine/core";
import { Link } from "react-router-dom";

import { useState } from "react";
import { colorAvatar, stringToColor } from "../../misc/mixins_mantine";
import { AppLogo } from "../../misc/toolkit_common";
import { SlackIntegration } from "../../misc/toolkit_slack";
import { usePaginationStore } from "../../state/store";
import { GithubDemoAppRecord, GithubDemoRecord } from "../../tooling/demo";
import PaginationControl from "../common/PaginationControl";
import { TableHeader, TableHeaderCell } from "../common/TableHeader";

const badgeColorByRisk = ["gray", "orange", "red", "pink"];
const badgeLabelByRisk = ["Low", "Medium", "High", "Urgent"];

const cellApproved = (
  integration: GithubDemoAppRecord,
  instanceId: string,
  theme: MantineTheme,
  align: "left" | "right"
) => {
  return !integration.installedOn ? (
    <Badge
      color="pink"
      size={align === "right" ? "md" : "sm"}
      radius="xl"
      component={Link}
      to={`/applications/slack/${instanceId}/integrations/${integration.id}?t=scopes`}
      style={{ cursor: "pointer" }}
    >
      Not approved
    </Badge>
  ) : (
    align === "right" && (
      <Group position={align}>
        <Text>{integration.installedOn}</Text>
        {/* <Tooltip
          label={integration.installedBy}
          withArrow
          color={theme.colors.dark[3]}
          transitionProps={{
            transition: "fade",
          }}
        > */}
        <Avatar
          radius="xl"
          size="sm"
          p={0}
          m={0}
          {...colorAvatar(integration.installedBy || " ")}
        />
        {/* </Tooltip> */}
      </Group>
    )
  );
};

interface TableGithubIntegrationsProps {
  fullRecord: GithubDemoRecord;
  filterConditions: string[];
  searchCondition: string;
  activePage: number;
  setActivePage: (page: number) => void;
  narrowView?: boolean;
}

export function TableGithubIntegrations({
  fullRecord,
  filterConditions,
  searchCondition,
  activePage,
  setActivePage,
  narrowView = false,
}: TableGithubIntegrationsProps) {
  const theme = useMantineTheme();
  console.log("theme spacing", theme.spacing);

  const [sortBy, setSortBy] = useState<keyof SlackIntegration | null>(null);
  const [reverseSortDirection, setReverseSortDirection] = useState(false);

  const setSorting = (field: keyof SlackIntegration) => {
    const reversed = field === sortBy ? !reverseSortDirection : false;
    setReverseSortDirection(reversed);
    setSortBy(field);
  };

  const { pageSize, setPageSize } = usePaginationStore.get(
    "github-instance-integrations"
  )!((state) => ({
    pageSize: state.pageSize,
    setPageSize: state.setPageSize,
  }));

  const integrations = fullRecord.apps;
  const instanceId = fullRecord.instance.instanceId;

  const rows = integrations.filter((integration) => {
    let searchOk = true;
    if (searchCondition) {
      searchOk =
        integration.label
          ?.toLowerCase()
          .includes(searchCondition.toLowerCase()) || false;
    }

    const integrationActive = "sessions-active";

    const integrationShadow = !integration.installedBy
      ? "shadow-yes"
      : "shadow-no";

    const integrationUsage = "usage-some";

    if (
      filterConditions.length === 0 ||
      (filterConditions.length !== 0 &&
        (!filterConditions.find((c) => c.includes("risk-")) ||
          filterConditions.includes(`risk-${integration.riskLevel}`)) &&
        (!filterConditions.find((c) => c.includes("usage-")) ||
          filterConditions.includes(integrationUsage)) &&
        (!filterConditions.find((c) => c.includes("sessions-")) ||
          filterConditions.includes(integrationActive)) &&
        (!filterConditions.find((c) => c.includes("shadow-")) ||
          filterConditions.includes(integrationShadow)))
    ) {
      return searchOk;
    } else {
      return false;
    }
  });

  const rowsFilteredLength = rows.length;

  const pageSizeAdjusted = pageSize !== 0 ? pageSize! : rowsFilteredLength;

  const rowsToDisplay = rows
    .sort((a, b) => {
      if (!sortBy) {
        return (
          (b.riskLevel! || 0) -
          (a.riskLevel! || 0) * (reverseSortDirection ? -1 : 1)
        );
      }

      switch (sortBy) {
        case "appName":
          return (
            b.label!.localeCompare(a.label!, "en") *
            (reverseSortDirection ? -1 : 1)
          );
        case "riskLevel":
          return (
            ((b.riskLevel! || 0) - (a.riskLevel! || 0)) *
            (reverseSortDirection ? -1 : 1)
          );
        case "usage":
          return (
            ((true ? 1 : 0) - (true ? 1 : 0)) * (reverseSortDirection ? -1 : 1)
          );
        case "activeUsers":
          return (b.users - a.users) * (reverseSortDirection ? -1 : 1);
        case "teams":
          return (
            ((b.repos.length || 0) - (a.repos.length || 0)) *
            (reverseSortDirection ? -1 : 1)
          );
        case "approvedBy":
          return (
            (b.installedBy || "").localeCompare(a.installedBy || "") *
            (reverseSortDirection ? -1 : 1)
          );

        default:
          return (
            (b.riskLevel! || 0) -
            (a.riskLevel! || 0) * (reverseSortDirection ? -1 : 1)
          );
      }
    })
    .slice((activePage - 1) * pageSizeAdjusted, activePage * pageSizeAdjusted)
    .map((integration) => {
      return (
        <tr key={integration.id} style={{ height: "60px" }}>
          <td style={{ textAlign: "left", paddingLeft: 20 }}>
            <UnstyledButton
              component={Link}
              to={`/applications/slack/${instanceId}/integrations/${integration.id}`}
            >
              <Group spacing="xs">
                <AppLogo integration={integration!} width={24} height={24} />
                <div style={{ width: "calc(100% - 40px)" }}>
                  <Stack spacing={0}>
                    <Text
                      ml="xs"
                      size="xs"
                      style={{ textOverflow: "ellipsis", overflow: "hidden" }}
                      lineClamp={2}
                      weight={600}
                    >
                      {integration.label}
                    </Text>
                    {false ?? (
                      <Container style={{ width: "100%" }} pl="xs">
                        {cellApproved(integration, instanceId!, theme, "left")}
                      </Container>
                    )}
                  </Stack>
                </div>
              </Group>
            </UnstyledButton>
          </td>
          <td style={{ textAlign: "left", paddingLeft: 10 }}>
            {/* <Tooltip
              label=<Stack>
                {
                  // integration.riskLevelReason
                  ""?.split(".").map((r) => r && <Text key={r}>{r}.</Text>)
                }
                {integration.riskLevel! >= 10 && (
                  <Text>
                    Risk score:{" "}
                    <strong>
                      {Math.max(0, Math.min(100, integration.riskLevel!))}
                    </strong>
                  </Text>
                )}
              </Stack>
              withArrow
              multiline
              width={300}
              color={theme.colors.dark[3]}
              transitionProps={{
                transition: "fade",
              }}
            > */}
            {
              // !integration.isUsageCalculated
              false ? (
                <Badge variant="light" size="md" color="gray" radius="xl">
                  <Loader variant="dots" color="gray" size="xs"></Loader>
                </Badge>
              ) : (
                <Badge
                  variant="light"
                  size={narrowView ? "sm" : "md"}
                  color={badgeColorByRisk[integration.riskLevel!]}
                  radius="xl"
                  component={Link}
                  to={`/applications/slack/${instanceId}/integrations/${integration.id}?t=scopes`}
                  style={{ cursor: "pointer" }}
                >
                  {badgeLabelByRisk[integration.riskLevel!]}
                </Badge>
              )
            }
            {/* </Tooltip> */}
          </td>

          <td style={{ textAlign: "left", paddingLeft: 10 }}>
            {/* {!integration.isUsageCalculated */}
            {false ? (
              <Loader variant="dots" color="gray" size="xs"></Loader>
            ) : true ? (
              <>
                <Text
                  size="xs"
                  weight={600}
                  component={Link}
                  to={`/applications/slack/${instanceId}/integrations/${integration.id}?t=sessions`}
                  style={{ cursor: "pointer", width: 20 }}
                >
                  {integration.users}
                </Text>
              </>
            ) : (
              "-"
            )}
          </td>

          {!narrowView && (
            <td style={{ textAlign: "left", paddingLeft: 10 }}>
              {/* {!integration.isUsageCalculated */}
              {false ? (
                <Loader variant="dots" color="gray" size="xs"></Loader>
              ) : true ? (
                <Text
                  size="xs"
                  weight={600}
                  component={Link}
                  to={`/applications/slack/${instanceId}/integrations/${integration.id}?t=sessions`}
                  style={{ cursor: "pointer", width: 20 }}
                >
                  {(!integration.access || integration.access.length === 0) && (
                    <Badge
                      variant="light"
                      size="md"
                      color="red"
                      radius="xl"
                      mr={4}
                    >
                      UNKNOWN
                    </Badge>
                  )}
                  {integration.access?.some(
                    (scope) =>
                      scope.includes("read") ||
                      scope.includes("history") ||
                      scope.includes("identity") ||
                      scope.includes("identify")
                  ) && (
                    <Badge
                      variant="light"
                      size="md"
                      color="green"
                      radius="xl"
                      mr={4}
                    >
                      Read
                    </Badge>
                  )}
                  {integration.access?.some(
                    (scope) =>
                      scope.includes("write") ||
                      scope.includes("post") ||
                      scope.includes("send")
                  ) && (
                    <Badge
                      variant="light"
                      size="md"
                      color="blue"
                      radius="xl"
                      mr={4}
                    >
                      Write
                    </Badge>
                  )}
                  {integration.access?.some(
                    (scope) =>
                      scope.includes("admin") ||
                      scope.includes("manage") ||
                      scope.includes("delete")
                  ) && (
                    <Badge
                      variant="light"
                      size="md"
                      color="red"
                      radius="xl"
                      mr={4}
                    >
                      Admin
                    </Badge>
                  )}
                  {integration.access?.some((scope) =>
                    scope.includes("bot")
                  ) && (
                    <Badge
                      variant="light"
                      size="md"
                      color="violet"
                      radius="xl"
                      mr={4}
                    >
                      Bot
                    </Badge>
                  )}
                </Text>
              ) : (
                "-"
              )}
            </td>
          )}

          <td style={{ textAlign: "left", paddingLeft: 10 }}>
            {/* {!integration.isUsageCalculated */}
            {false ? (
              <Loader variant="dots" color="gray" size="xs"></Loader>
            ) : integration.repos ? (
              // <Text
              //   size="xs"
              //   weight={600}
              //   component={Link}
              //   to={`/applications/slack/${instanceId}/integrations/${integration.id}?t=sessions`}
              //   style={{ cursor: "pointer", width: 20 }}
              // >
              integration.repos.map((team) => (
                <Badge
                  variant="light"
                  size="md"
                  color={stringToColor(team)}
                  radius="xl"
                  mr={4}
                >
                  {team}
                </Badge>
              ))
            ) : (
              // </Text>
              "-"
            )}
          </td>

          {
            <td align="right" style={{ paddingRight: 20 }}>
              {cellApproved(integration, instanceId!, theme, "right")}
            </td>
          }
        </tr>
      );
    });

  return (
    <>
      <Table
        verticalSpacing="xs"
        horizontalSpacing={0}
        fontSize="xs"
        style={{ tableLayout: "fixed" }}
      >
        {tableHeader(sortBy, reverseSortDirection, setSorting, narrowView)}
        <tbody>{rowsToDisplay}</tbody>
      </Table>
      <Divider />
      <Container
        pl="md"
        pr="lg"
        pt="lg"
        pb="lg"
        fluid
        style={{ width: "100%" }}
      >
        <PaginationControl
          activePage={activePage}
          setActivePage={setActivePage}
          pageSize={pageSize}
          setPageSize={setPageSize}
          rowsNumber={rowsFilteredLength}
        />
      </Container>
    </>
  );
}
const tableHeader = (
  sortBy: string | null,
  reverseSortDirection: boolean,
  setSorting: (field: keyof SlackIntegration) => void,
  narrowView: boolean
) => {
  return (
    <TableHeader>
      <TableHeaderCell
        sorted={sortBy === "appName"}
        reversed={reverseSortDirection}
        onSort={() => setSorting("appName")}
        style={{ textAlign: "left", minWidth: 280 }}
        pl={20}
      >
        Integration
      </TableHeaderCell>
      <TableHeaderCell
        sorted={sortBy === "riskLevel"}
        reversed={reverseSortDirection}
        onSort={() => setSorting("riskLevel")}
        style={
          !narrowView
            ? { textAlign: "left", width: 140 }
            : { textAlign: "left", width: 95 }
        }
        description={
          <Text size="xs" weight={300}>
            Vantyr’s risk score algorithm factors in the following variables:
            <br />
            <ul style={{ listStyle: "inside", paddingLeft: 0 }}>
              <li>permissions/scopes granted</li>
              <li>real and potential data exposure</li>
              <li>users, their roles, and access levels</li>
              <li>frequency and nature of API calls made by the app</li>
              <li>approval by a Slack admin</li>
            </ul>
          </Text>
        }
      >
        Risk
      </TableHeaderCell>
      {/* <TableHeaderCell
        sorted={sortBy === "exposedFieldsCountClassifiedTotal"}
        reversed={reverseSortDirection}
        onSort={() => setSorting("exposedFieldsCountClassifiedTotal")}
        style={
          !narrowView
            ? { textAlign: "left", width: 160 }
            : { textAlign: "left", width: 150 }
        }
        description={
          <Text size="xs" weight={300}>
            Displays total count of unique instances of PII, PHI, PCI and
            Business Sensitive data exposed to the integration.
          </Text>
        }
      >
        Data exposed
      </TableHeaderCell> */}
      {/* <TableHeaderCell
        sorted={sortBy === "usageVolumeBytes"}
        reversed={reverseSortDirection}
        onSort={() => setSorting("usageVolumeBytes")}
        style={
          !narrowView
            ? { textAlign: "left", width: 130 }
            : { textAlign: "left", width: 100 }
        }
      >
        Data flow
      </TableHeaderCell> */}
      {/* {!narrowView && (
        <TableHeaderCell
          sorted={sortBy === "classifiedFields"}
          reversed={reverseSortDirection}
          onSort={() => setSorting("classifiedFields")}
          style={{ textAlign: "left", width: 160 }}
        >
          Data types
        </TableHeaderCell>
      )} */}
      <TableHeaderCell
        sorted={sortBy === "users"}
        reversed={reverseSortDirection}
        onSort={() => setSorting("activeUsers")}
        style={
          !narrowView
            ? { textAlign: "left", width: 100 }
            : { textAlign: "left", width: 80 }
        }
      >
        Users
      </TableHeaderCell>
      {!narrowView && (
        <TableHeaderCell
          sorted={sortBy === "activeUsers"}
          reversed={reverseSortDirection}
          onSort={() => setSorting("activeUsers")}
          // style={
          //   !narrowView
          //     ? { textAlign: "left", maxWidth: 100 }
          //     : { textAlign: "left", width: 80 }
          // }
        >
          Access
        </TableHeaderCell>
      )}
      <TableHeaderCell
        sorted={sortBy === "teams"}
        reversed={reverseSortDirection}
        onSort={() => setSorting("teams")}
        style={
          !narrowView
            ? { textAlign: "left", width: 280 }
            : { textAlign: "left", width: 140 }
        }
      >
        Repositories
      </TableHeaderCell>
      {
        <TableHeaderCell
          sorted={sortBy === "createdOn"}
          reversed={reverseSortDirection}
          onSort={() => setSorting("createdOn")}
          style={{ textAlign: "right", width: 140, paddingRight: 20 }}
          position="right"
          pr={20}
          description={
            <Text size="xs" weight={300} align="left">
              Displays the app date of approval and the Slack admin that
              approved the integration.
              <br />
              <br />
              “Not Approved” apps are shadow integrations that have{" "}
              <strong>not</strong> been approved / installed by any admin.
            </Text>
          }
        >
          Approved
        </TableHeaderCell>
      }
    </TableHeader>
  );
};
