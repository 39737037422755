import {
  Avatar,
  Badge,
  Center,
  Col,
  Container,
  Grid,
  Group,
  HoverCard,
  Paper,
  Stack,
  Text,
  Title,
  UnstyledButton,
} from "@mantine/core";
import {
  IconEyeOff,
  IconInfoCircle,
  IconNetworkOff,
  IconPackgeExport,
  IconPlug,
  IconPlugConnected,
  IconShieldX,
  IconUserExclamation,
  IconUserOff,
  IconUserX,
  IconUsers,
} from "@tabler/icons";
import { parse } from "fecha";
import { SalesforceIntegrationsAndActivityRecord } from "../../api/generated";
import { VANTYR_API_DATETIME_FORMAT } from "../../constants/constants";
import { breakdownRisk, daysSinceSfTs } from "../../misc/toolkit_salesforce";
import { useConfigStore } from "../../state/store";
import { demoMode } from "../../tooling/demo";
import RiskBreakdown from "../common/RiskBreakdown";

interface ViewSalesforceDashboardProps {
  instanceRecord: SalesforceIntegrationsAndActivityRecord;
  onIntegrationsClicked: (riskLevel: number) => void;
  onFilterSelected: (filters: string[]) => void;
}

const ViewSalesforceDashboard = ({
  instanceRecord,
  onIntegrationsClicked,
  onFilterSelected,
}: ViewSalesforceDashboardProps) => {
  const riskBreakdown = breakdownRisk(instanceRecord);

  const shadowIntegrations = instanceRecord.oauthStats?.integrations?.filter(
    (x) => x.appId === x.appName
  ).length!;

  const classifiedDataFlowHighRiskIntegrations =
    instanceRecord.integrations?.filter(
      (i) =>
        i.riskLevel === 2 &&
        i.classifiedFields &&
        i.classifiedFields!.length !== 0 &&
        i.classifiedFields.filter(
          (f: string) => f.includes("::PII") || f.includes("::Sensitive")
        ).length > 0
    ).length!;

  const noDataFlowHighRiskActiveIntegrations =
    instanceRecord.integrations!.filter(
      (i) =>
        (!i.queriedEntities || i.queriedEntities.length !== 0) &&
        i.riskLevel === 2 &&
        i.oauthTokens &&
        i.oauthTokens!.length !== 0
    ).length!;

  const highRiskFewUsersIntegrations = instanceRecord.integrations!.filter(
    (i) =>
      ([...new Set(i.oauthTokens!.map((t) => t.userName))].length || 0) < 10 &&
      i.riskLevel === 2
  ).length!;

  const highRiskRecentIntegrations = instanceRecord.integrations!.filter(
    (i) =>
      i.riskLevel === 2 &&
      i.createdOn &&
      Math.abs(
        new Date().getTime() -
          parse(i!.createdOn!, VANTYR_API_DATETIME_FORMAT)!.getTime()
      ) <
        1000 * 60 * 60 * 24 * 30
  ).length!;

  return (
    <Container fluid my="xl" mx="xs">
      <Grid p={0} gutter="xl">
        <Col xl={5}>
          <Paper shadow="xl" p="md" style={{ height: "100%" }}>
            <Title order={5} mb="xl">
              Insights
            </Title>
            {shadowIntegrations > 0 && (
              <Group spacing={8} mb="md">
                <Avatar color="pink.6" radius="xl" size="sm">
                  <IconShieldX stroke={1} size={16} />
                </Avatar>
                {shadowIntegrations > 1 ? (
                  <Text
                    weight={500}
                    size="sm"
                  >{`${shadowIntegrations} shadow integrations have active connections`}</Text>
                ) : (
                  <Text
                    weight={500}
                    size="sm"
                  >{`${shadowIntegrations} shadow integration has active connections`}</Text>
                )}
                <HoverCard width="300px" position="top" withArrow shadow="md">
                  <HoverCard.Dropdown sx={{ pointerEvents: "none" }}>
                    <Text size="sm">
                      Shadow integrations have not been approved / installed by
                      a Salesforce administrator. The permissions granted to
                      such integrations are not known.
                    </Text>
                  </HoverCard.Dropdown>
                  <HoverCard.Target>
                    <Center style={{ height: 21, width: 21 }}>
                      <IconInfoCircle size={14} stroke={1} />
                    </Center>
                  </HoverCard.Target>
                </HoverCard>
              </Group>
            )}
            {classifiedDataFlowHighRiskIntegrations > 0 && (
              <Group spacing={8} mb="md">
                <Avatar color="indigo.6" radius="xl" size="sm">
                  <IconPackgeExport stroke={1} size={16} />
                </Avatar>
                {classifiedDataFlowHighRiskIntegrations > 1 ? (
                  <Text
                    weight={500}
                    size="sm"
                  >{`${classifiedDataFlowHighRiskIntegrations} high risk integrations are pulling sensitive data`}</Text>
                ) : (
                  <Text
                    weight={500}
                    size="sm"
                  >{`${classifiedDataFlowHighRiskIntegrations} high risk integration is pulling sensitive data`}</Text>
                )}
                <HoverCard width="300px" position="top" withArrow shadow="md">
                  <HoverCard.Dropdown sx={{ pointerEvents: "none" }}>
                    <Text size="sm">
                      Vantyr monitors what data types each integration is
                      pulling from your Salesforce instance, and classifies them
                      based on Salesforce data-type metadata and an internal
                      Vantyr knowledge base.
                    </Text>
                  </HoverCard.Dropdown>
                  <HoverCard.Target>
                    <Center style={{ height: 21, width: 21 }}>
                      <IconInfoCircle size={14} stroke={1} />
                    </Center>
                  </HoverCard.Target>
                </HoverCard>
              </Group>
            )}
            {noDataFlowHighRiskActiveIntegrations > 0 && (
              <Group spacing={8} mb="md">
                <Avatar color="orange.6" radius="xl" size="sm">
                  <IconPlug stroke={1} size={16} />
                </Avatar>
                {noDataFlowHighRiskActiveIntegrations > 1 ? (
                  <Text
                    weight={500}
                    size="sm"
                  >{`${noDataFlowHighRiskActiveIntegrations} unused high risk integrations have active sessions`}</Text>
                ) : (
                  <Text
                    weight={500}
                    size="sm"
                  >{`${noDataFlowHighRiskActiveIntegrations} unused high risk integration has active sessions`}</Text>
                )}
                <HoverCard width="300px" position="top" withArrow shadow="md">
                  <HoverCard.Dropdown sx={{ pointerEvents: "none" }}>
                    <Text size="sm">
                      Vantyr has not detected any activity for these
                      integrations in the last{" "}
                      {instanceRecord.observedPeriodInDays! > 1
                        ? `${instanceRecord.observedPeriodInDays} days`
                        : "1 day"}
                      . This could be due to the integration not being used, or
                      the integration not being configured correctly.
                    </Text>
                  </HoverCard.Dropdown>
                  <HoverCard.Target>
                    <Center style={{ height: 21, width: 21 }}>
                      <IconInfoCircle size={14} stroke={1} />
                    </Center>
                  </HoverCard.Target>
                </HoverCard>
              </Group>
            )}
            {highRiskFewUsersIntegrations > 0 && (
              <Group spacing={8}>
                <Avatar color="red.6" radius="xl" size="sm">
                  <IconUserExclamation stroke={1} size={16} />
                </Avatar>
                <Text
                  weight={500}
                  size="sm"
                >{`${highRiskFewUsersIntegrations} high risk integrations have less than 10 users`}</Text>
                <HoverCard width="400px" position="top" withArrow shadow="md">
                  <HoverCard.Dropdown
                    sx={{
                      pointerEvents: "none",
                      // backgroundColor: "#E8E8E8",
                    }}
                  >
                    <Text size="sm">
                      Vantyr’s risk score algorithm factors in the following
                      variables:
                      <br />
                      <ul
                        style={{
                          listStyle: "inside",
                          paddingLeft: 0,
                        }}
                      >
                        <li>permissions/scopes granted</li>
                        <li>real and potential data exposure</li>
                        <li>users, their roles, and access levels</li>
                        <li>
                          frequency and nature of API calls made by the app
                        </li>
                        <li>approval by a Salesforce admin</li>
                      </ul>
                    </Text>
                  </HoverCard.Dropdown>
                  <HoverCard.Target>
                    <Center style={{ height: 21, width: 21 }}>
                      <IconInfoCircle size={14} stroke={1} />
                    </Center>
                  </HoverCard.Target>
                </HoverCard>
              </Group>
            )}
            {highRiskRecentIntegrations > 0 && (
              <Group spacing={8}>
                <Avatar color="red.6" radius="xl" size="sm">
                  <IconPlugConnected stroke={1} size={16} />
                </Avatar>
                <Text
                  weight={500}
                  size="sm"
                >{`${highRiskRecentIntegrations} high risk integrations connected in last 30 days`}</Text>
                <HoverCard width="400px" position="top" withArrow shadow="md">
                  <HoverCard.Dropdown
                    sx={{
                      pointerEvents: "none",
                      // backgroundColor: "#E8E8E8",
                    }}
                  >
                    <Text size="sm">
                      Vantyr’s risk score algorithm factors in the following
                      variables:
                      <br />
                      <ul
                        style={{
                          listStyle: "inside",
                          paddingLeft: 0,
                        }}
                      >
                        <li>permissions/scopes granted</li>
                        <li>real and potential data exposure</li>
                        <li>users, their roles, and access levels</li>
                        <li>
                          frequency and nature of API calls made by the app
                        </li>
                        <li>approval by a Salesforce admin</li>
                      </ul>
                    </Text>
                  </HoverCard.Dropdown>
                  <HoverCard.Target>
                    <Center style={{ height: 21, width: 21 }}>
                      <IconInfoCircle size={14} stroke={1} />
                    </Center>
                  </HoverCard.Target>
                </HoverCard>
              </Group>
            )}
          </Paper>
        </Col>
        <Col xl={7}>
          <Paper shadow="xl" p="xl">
            <Grid>
              <Col md={3} sm={6}>
                <Stack align="center" spacing="xs">
                  <Title order={6}>Total integrations</Title>
                  <UnstyledButton
                    p={0}
                    m={0}
                    onClick={() => onIntegrationsClicked(-1)}
                  >
                    <Title order={1}>
                      {instanceRecord.integrations?.length}
                    </Title>
                  </UnstyledButton>
                </Stack>
              </Col>
              <Col md={3} sm={6}>
                <Stack align="center" spacing="xs">
                  <Group spacing="xs">
                    <IconEyeOff size="1rem" color="gray" stroke={1} />
                    <Text weight="600" size="sm" color="dimmed">
                      Shadow
                    </Text>
                  </Group>
                  <UnstyledButton
                    p={0}
                    m={0}
                    onClick={() => onFilterSelected(["shadow-yes"])}
                  >
                    <Title order={2} color="pink">
                      {
                        instanceRecord.integrations?.filter((x) => !x.createdBy)
                          .length
                        // instanceRecord.oauthStats?.integrations?.filter(
                        //   (x) => x.appId === x.appName
                        // ).length
                      }
                    </Title>
                  </UnstyledButton>
                </Stack>
              </Col>
              <Col md={3} sm={6}>
                <Stack align="center" spacing="xs">
                  <Group spacing="xs">
                    <IconNetworkOff size="1rem" color="gray" stroke={1} />
                    <Text weight="600" size="sm" color="dimmed">
                      Unused
                    </Text>
                  </Group>
                  <Title order={2} color="orange">
                    {
                      instanceRecord.oauthStats?.integrations?.filter(
                        (x) =>
                          daysSinceSfTs(x.lastUsedOn!) > 365 &&
                          instanceRecord.integrations?.find(
                            (y) =>
                              y.connectedApplicationId === x.appId ||
                              (!y.connectedApplicationId &&
                                y.name === x.appName)
                          )?.isZombie === false
                      ).length
                    }
                  </Title>
                </Stack>
              </Col>
              <Col md={3} sm={6}>
                <Stack align="center" spacing="xs">
                  <Group spacing="xs">
                    <IconUserOff size="1rem" color="gray" stroke={1} />
                    <Text weight="600" size="sm" color="dimmed">
                      Inactive
                    </Text>
                  </Group>
                  <UnstyledButton
                    p={0}
                    m={0}
                    onClick={() => onFilterSelected(["sessions-inactive"])}
                  >
                    <Title order={2} color="gray">
                      {
                        instanceRecord.integrations?.filter(
                          (x) =>
                            x.isZombie ||
                            !x.oauthTokens ||
                            x.oauthTokens.length === 0
                        ).length
                      }
                    </Title>
                  </UnstyledButton>
                </Stack>
              </Col>
            </Grid>
          </Paper>
          <Paper shadow="xl" p="xl" mt="xl">
            <Grid>
              <Col md={3} sm={6}>
                <Stack align="center" spacing="xs">
                  <Title order={6} style={{ textAlign: "center" }}>
                    Salesforce users
                  </Title>
                  <Title order={1}>
                    {demoMode
                      ? 546
                      : instanceRecord.oauthStats
                          ?.totalSalesforceUsersActiveCount}
                  </Title>
                </Stack>
              </Col>
              {/* <Col span={1}>
                <Box></Box>
              <Divider orientation="vertical" variant="dashed" />
                <Box></Box>
            </Col> */}
              <Col md={3} sm={6}>
                <Stack align="center" spacing="xs">
                  <Group spacing="xs">
                    <IconPlugConnected size="1rem" color="gray" stroke={1} />
                    <Text weight="600" size="sm" color="dimmed">
                      Using apps
                    </Text>
                  </Group>
                  <Title order={2} color="teal">
                    {demoMode
                      ? 281
                      : instanceRecord.oauthStats
                          ?.totalActiveUsersWithIntegrationsCount}
                  </Title>
                </Stack>
              </Col>
              <Col md={3} sm={6}>
                <Stack align="center" spacing="xs">
                  <Group spacing="xs">
                    <IconUserX size="1rem" color="gray" stroke={1} />
                    <Text weight="600" size="sm" color="dimmed">
                      Inactive
                    </Text>
                  </Group>
                  <Title order={2} color="gray">
                    {demoMode
                      ? 11
                      : instanceRecord.oauthStats?.users?.reduce(
                          (acc, x) =>
                            acc +
                            (x.userIsActive &&
                            daysSinceSfTs(x.lastLoginOn!) > 180
                              ? 1
                              : 0),
                          0
                        )}
                  </Title>
                </Stack>
              </Col>
              <Col md={3} sm={6}>
                <Stack align="center" spacing="xs">
                  <Group spacing="xs">
                    <IconUsers size="1rem" color="gray" stroke={1} />
                    <Text weight="600" size="sm" color="dimmed">
                      Roles
                    </Text>
                  </Group>
                  <Title order={2} color="blue">
                    {instanceRecord.oauthStats?.totalProfiles}
                  </Title>
                </Stack>
              </Col>
            </Grid>
          </Paper>
        </Col>
        <Col xl={5}>
          <Paper shadow="xl" p="md" style={{ height: "100%" }}>
            <Title order={5} pb="md">
              Risk breakdown
            </Title>
            <RiskBreakdown
              breakdown={riskBreakdown}
              onSectionClicked={onIntegrationsClicked}
            />
          </Paper>
        </Col>
        <Col xl={7}>
          <Paper shadow="xl" p="xl" style={{ height: "100%" }}>
            <Center style={{ height: "100%" }}>
              <Grid gutter="xl">
                <Col md={12} sm={12}>
                  <Stack align="center" spacing={0} mb="sm">
                    <Title order={6}>Total exposure</Title>
                    <Title order={1} mt={10}>
                      {instanceRecord.exposedFieldsCountClassifiedTotal?.toLocaleString()}
                    </Title>
                    <Text size="sm" color="dimmed">
                      classified data records
                    </Text>
                  </Stack>
                </Col>
                {instanceRecord.exposedFieldsCountPII! > 0 && (
                  <Col span="auto">
                    <Stack align="center" spacing="xs">
                      <Badge color="pink" size="md" radius="xl" variant="light">
                        PII
                      </Badge>
                      <Title order={2} color="pink">
                        {instanceRecord.exposedFieldsCountPII?.toLocaleString()}
                      </Title>
                    </Stack>
                  </Col>
                )}
                {instanceRecord.exposedFieldsCountPHI! > 0 && (
                  <Col span="auto">
                    <Stack align="center" spacing="xs">
                      <Badge
                        color="green"
                        size="md"
                        radius="xl"
                        variant="light"
                      >
                        PHI
                      </Badge>
                      <Title order={2} color="green">
                        {instanceRecord.exposedFieldsCountPHI?.toLocaleString()}
                      </Title>
                    </Stack>
                  </Col>
                )}
                {instanceRecord.exposedFieldsCountPCI! > 0 && (
                  <Col span="auto">
                    <Stack align="center" spacing="xs">
                      <Badge color="red" size="md" radius="xl" variant="light">
                        PCI
                      </Badge>
                      <Title order={2} color="red">
                        {instanceRecord.exposedFieldsCountPCI?.toLocaleString()}
                      </Title>
                    </Stack>
                  </Col>
                )}
                {instanceRecord.exposedFieldsCountSensitive! > 0 && (
                  <Col span="auto">
                    <Stack align="center" spacing="xs">
                      <Badge
                        color="grape"
                        size="md"
                        radius="xl"
                        variant="light"
                      >
                        Sensitive
                      </Badge>
                      <Title order={2} color="grape">
                        {instanceRecord.exposedFieldsCountSensitive?.toLocaleString()}
                      </Title>
                    </Stack>
                  </Col>
                )}
              </Grid>
            </Center>
          </Paper>
        </Col>
      </Grid>
    </Container>
  );
};

export default ViewSalesforceDashboard;
