/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable react/prop-types */
import {
  Container,
  Group,
  Header,
  Image,
  useMantineTheme,
} from "@mantine/core";
import NavBreadcrumbs from "./NavBreadcrumbs";
import { ProductLogoAssets } from "./ProductLogoAssets";
import ThemeToggleButton from "../common/ThemeToggleButton";

const AppHeader = () => {
  const theme = useMantineTheme();
  return (
    <Header
      height={60}
      pl="xs"
      pr="md"
      style={{
        borderBottomWidth: "0px",
        boxShadow:
          "rgba(0, 0, 0, 0.05) 0px 1px 3px 0px, rgba(0, 0, 0, 0.05) 0px 20px 25px -5px, rgba(0, 0, 0, 0.04) 0px 10px 10px -5px",
      }}
    >
      <Container
        fluid
        p="0"
        m={0}
        style={{
          flex: "flex-grow",
          flexDirection: "row",
          alignSelf: "stretch",
        }}
      >
        <Group p={0} m={0}>
          <Group pt={6} style={{ width: 280, alignItems: "left" }}>
            <Image
              src={ProductLogoAssets.get(`vantyr-${theme.colorScheme}`)}
              height={55}
              width="auto"
              fit="contain"
            />
          </Group>
          <Container
            fluid
            p="0"
            m={0}
            style={{
              flexGrow: 1,
            }}
          >
            <Group position="apart" p={0} m={0} pr="xl">
              <NavBreadcrumbs />
              <ThemeToggleButton />
            </Group>
          </Container>
        </Group>
      </Container>
    </Header>
  );
};

export default AppHeader;
