import { Group, Image, Menu, Text, Title, Transition } from "@mantine/core";
import { closeAllModals, openConfirmModal } from "@mantine/modals";
import { IconCheck, IconDots, IconLink, IconX } from "@tabler/icons";
import {
  SalesforceApi,
  SalesforceInstanceRecord,
  SalesforceIntegrationsAndActivityRecord,
} from "../../api/generated";
import { periodAsText } from "../../misc/toolkit_common";
import { ProductLogoAssets } from "../common/ProductLogoAssets";
import Summary from "../common/Summary";
import { useQueryClient } from "react-query";
import { showNotification } from "@mantine/notifications";
import { useAccessStore } from "../../state/store";

interface SalesforceInstanceSummaryProps {
  instance: SalesforceInstanceRecord;
}

interface SummarySalesforceIntegrationsProps {
  fullRecord: SalesforceIntegrationsAndActivityRecord;
  selection: string[];
}

export const SalesforceInstanceSummary = ({
  instance,
}: SalesforceInstanceSummaryProps) => {
  return (
    <Group position="left">
      <Image
        src={ProductLogoAssets.get("salesforce")}
        fit="contain"
        width={40}
        height={40}
      />
      <div>
        <Title order={6}>
          Salesforce {instance?.orgType} @ {instance?.orgName}
        </Title>
      </div>
    </Group>
  );
};

export const SummarySalesforceIntegrations = ({
  fullRecord,
  selection,
}: SummarySalesforceIntegrationsProps) => {
  const queryClient = useQueryClient();

  const loggedInUserRole = useAccessStore((state) => state.userRole);
  const loggedInUserIsNotAdmin = loggedInUserRole === "Viewer";

  const openIntegrationsApproveModal = (selection: string[]) =>
    openConfirmModal({
      title: `Approve ${selection.length} integrations?`,
      radius: "md",
      closeButtonProps: { radius: "xl" },
      cancelProps: { radius: "xl" },
      centered: true,
      children: (
        <Text size="sm">
          This action will install each of the selected integrations in your
          Salesforce instance. You will be able to view additional details about
          each integration, including author data and the permissions it
          requires.
          <br />
          <br />
          This also enables Vantyr to more precisely assess the data exposure
          and risk associated with each integration.
        </Text>
      ),
      labels: { confirm: "Approve", cancel: "Cancel" },
      confirmProps: { color: "green", radius: "xl" },
      onConfirm: () => {
        new SalesforceApi()
          .salesforceInstallApps(fullRecord.instance?.instanceId!, {
            integrationIds: selection,
          })
          .then((result) => {
            queryClient.invalidateQueries({
              queryKey: [`integrations_${fullRecord?.instance?.instanceId}`],
            });
            closeAllModals();
            if (result.status === 200) {
              showNotification({
                title: "Integrations approved!",
                message: `${selection.length} integrations have been successfully approved. `,
                color: "green",
              });
            } else {
              showNotification({
                title: "Oops!",
                message: `Something went wrong! Some integrations may not have been installed.`,
              });
            }
          });
      },
      onCancel: () => {},
    });

  const openIntegrationsRemoveModal = (selection: string[]) =>
    openConfirmModal({
      title: `Remove ${selection.length} integrations?`,
      radius: "md",
      closeButtonProps: { radius: "xl" },
      cancelProps: { radius: "xl" },
      centered: true,
      children: (
        <Text size="sm">
          This action will remove the selected integrations from your Salesforce
          instance, by revoking all associated session tokens and uninstalling
          the integration.
          <br />
          <br />
          This action does not prevent your end users from reconnecting the
          integration in the future.
        </Text>
      ),
      labels: { confirm: "Remove", cancel: "Cancel" },
      confirmProps: { color: "red", radius: "xl" },
      onConfirm: () => {
        new SalesforceApi()
          .salesforceRemoveApps(fullRecord.instance!.instanceId!, {
            integrationIds: selection,
            deleteTokens: fullRecord.integrations?.reduce(
              (acc, x) =>
                selection.includes(x.id!)
                  ? [...acc, ...x.oauthTokens!.map((t) => t.deleteToken!)]
                  : acc,
              [] as string[]
            ),
          })
          .then(() => {
            queryClient.invalidateQueries({
              queryKey: selection.map((s) => `integration_${s}`),
            });
            showNotification({
              title: "Integrations removed!",
              message: `${selection.length} integrations have been successfully removed.`,
              color: "green",
            });
          })
          .catch((err) => {
            showNotification({
              title: "Integration removal may have partially failed.",
              message: err.message,
              color: "red",
            });
          });
      },
      onCancel: () => {},
    });

  return (
    <Transition
      mounted={true}
      transition="fade"
      duration={400}
      timingFunction="ease"
    >
      {(styles) => (
        <Summary
          icon={selection.length === 0 ? IconLink : IconDots}
          label={`${fullRecord.integrations!.length} integrations`}
          subtext={
            selection.length > 0
              ? selection.length === fullRecord.integrations!.length
                ? "All selected"
                : `${selection.length} selected`
              : fullRecord.observedPeriodInDays! > 0 &&
                fullRecord.integrations?.find((x) =>
                  x.label?.includes("Vantyr")
                )?.usageVolumeBytes! > 0
              ? `Monitored over the last ${periodAsText(
                  fullRecord.observedPeriodInDays!
                )}`
              : fullRecord.observedPeriodInDays === 0 ||
                fullRecord.integrations?.find((x) =>
                  x.label?.includes("Vantyr")
                )?.usageVolumeBytes! === 0
              ? "Logging is OFF for this instance"
              : "Scanning for activity ..."
          }
          color={selection.length === 0 ? "gray" : "blue"}
          size="md"
          menu={
            selection.length === 0 ? undefined : (
              <Menu.Dropdown>
                <Menu.Item
                  disabled={loggedInUserIsNotAdmin}
                  onClick={() => {
                    openIntegrationsApproveModal(selection);
                  }}
                  icon={<IconCheck size={16} stroke={1} color="green" />}
                >
                  Approve selected
                </Menu.Item>
                <Menu.Divider />
                <Menu.Item
                  disabled={loggedInUserIsNotAdmin}
                  icon={<IconX size={16} stroke={1} color="red" />}
                  onClick={() => openIntegrationsRemoveModal(selection)}
                >
                  Remove selected
                </Menu.Item>
              </Menu.Dropdown>
            )
          }
        />
      )}
    </Transition>
  );
};
