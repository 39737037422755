import { Center, Loader, Paper, Space } from "@mantine/core";
import { useQuery } from "react-query";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { SlackApi } from "../../api/generated";
import { slackGetIntegrationsFromUserRecords } from "../../misc/toolkit_slack";
import { useAppsStore } from "../../state/store";
import { demoInstanceSlack, demoMode } from "../../tooling/demo";
import Oops from "../common/Oops";
import { ViewIntegrationSlack } from "../slack/ViewIntegrationSlack";

export function ScreenIntegrationSlack() {
  const { instanceId, integrationId } = useParams();

  const [activeTab, setActiveTab] = useSearchParams({});
  let navigate = useNavigate();

  const setIntegrationSelected = useAppsStore(
    (state) => state.setIntegrationSelected
  );

  const { isError, error, isLoading, data } = useQuery(
    `slack_integrations_${instanceId}`,
    async () => {
      if (demoMode) {
        return demoInstanceSlack;
      }

      const rsp = await new SlackApi().slackListIntegrations(instanceId!);
      return rsp.data;
    },
    {
      staleTime: 10 * 60 * 1000,
      refetchInterval: 10 * 60 * 1000,
    }
  );

  // if loading
  if (isLoading) {
    return (
      <Center style={{ height: "100%" }}>
        <Loader color="gray" variant="dots" />
      </Center>
    );
  }

  // if error
  if (isError || error) {
    return <Oops />;
  }

  const instance = data!;

  const integration = slackGetIntegrationsFromUserRecords(instance).find(
    (i) => i.apiAppId === integrationId
  )!;

  setIntegrationSelected(integration.appName);

  return (
    <Paper
      shadow="md"
      m="xl"
      p="xl"
      style={{ minWidth: 600, maxWidth: 1500, width: "auto" }}
    >
      <Space h="sm" />
      <ViewIntegrationSlack
        instance={instance}
        integration={integration}
        showBackButton={true}
        onBackButtonClick={() => navigate(`/applications/slack/${instanceId}`)}
        routedTab={activeTab.get("t") || "summary"}
        setRoutedTab={(tab) => setActiveTab({ t: tab })}
      />
    </Paper>
  );
}
