/* tslint:disable */
/* eslint-disable */
/**
 * Vantyr.Portal.API.Service
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, RawAxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError, operationServerMap } from '../base';
// @ts-ignore
import { SalesforceAppMenuItemRecord } from '../models';
// @ts-ignore
import { SalesforceInstallAppsRequestRecord } from '../models';
// @ts-ignore
import { SalesforceInstanceRecord } from '../models';
// @ts-ignore
import { SalesforceIntegrationsAndActivityRecord } from '../models';
// @ts-ignore
import { SalesforceRemoveAppsRequestRecord } from '../models';
// @ts-ignore
import { SalesforceRevokeTokensRequestRecord } from '../models';
// @ts-ignore
import { SalesforceStatusRecord } from '../models';
/**
 * SalesforceApi - axios parameter creator
 * @export
 */
export const SalesforceApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} instanceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        disconnect: async (instanceId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'instanceId' is not null or undefined
            assertParamExists('disconnect', 'instanceId', instanceId)
            const localVarPath = `/salesforce/instances/{instanceId}/disconnect`
                .replace(`{${"instanceId"}}`, encodeURIComponent(String(instanceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} instanceId 
         * @param {string} integrationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getIntegration: async (instanceId: string, integrationId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'instanceId' is not null or undefined
            assertParamExists('getIntegration', 'instanceId', instanceId)
            // verify required parameter 'integrationId' is not null or undefined
            assertParamExists('getIntegration', 'integrationId', integrationId)
            const localVarPath = `/salesforce/instances/{instanceId}/integrations/{integrationId}`
                .replace(`{${"instanceId"}}`, encodeURIComponent(String(instanceId)))
                .replace(`{${"integrationId"}}`, encodeURIComponent(String(integrationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listInstances: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/salesforce/instances`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} instanceId 
         * @param {boolean} [lightMode] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listIntegrations: async (instanceId: string, lightMode?: boolean, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'instanceId' is not null or undefined
            assertParamExists('listIntegrations', 'instanceId', instanceId)
            const localVarPath = `/salesforce/instances/{instanceId}/integrations`
                .replace(`{${"instanceId"}}`, encodeURIComponent(String(instanceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (lightMode !== undefined) {
                localVarQueryParameter['lightMode'] = lightMode;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} instanceId 
         * @param {SalesforceInstallAppsRequestRecord} salesforceInstallAppsRequestRecord 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        salesforceInstallApps: async (instanceId: string, salesforceInstallAppsRequestRecord: SalesforceInstallAppsRequestRecord, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'instanceId' is not null or undefined
            assertParamExists('salesforceInstallApps', 'instanceId', instanceId)
            // verify required parameter 'salesforceInstallAppsRequestRecord' is not null or undefined
            assertParamExists('salesforceInstallApps', 'salesforceInstallAppsRequestRecord', salesforceInstallAppsRequestRecord)
            const localVarPath = `/salesforce/instances/{instanceId}/installApps`
                .replace(`{${"instanceId"}}`, encodeURIComponent(String(instanceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(salesforceInstallAppsRequestRecord, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} code 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        salesforceOauthCallback: async (code: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'code' is not null or undefined
            assertParamExists('salesforceOauthCallback', 'code', code)
            const localVarPath = `/salesforce_oauth_callback`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (code !== undefined) {
                localVarQueryParameter['code'] = code;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} instanceId 
         * @param {SalesforceRemoveAppsRequestRecord} salesforceRemoveAppsRequestRecord 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        salesforceRemoveApps: async (instanceId: string, salesforceRemoveAppsRequestRecord: SalesforceRemoveAppsRequestRecord, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'instanceId' is not null or undefined
            assertParamExists('salesforceRemoveApps', 'instanceId', instanceId)
            // verify required parameter 'salesforceRemoveAppsRequestRecord' is not null or undefined
            assertParamExists('salesforceRemoveApps', 'salesforceRemoveAppsRequestRecord', salesforceRemoveAppsRequestRecord)
            const localVarPath = `/salesforce/instances/{instanceId}/removeApps`
                .replace(`{${"instanceId"}}`, encodeURIComponent(String(instanceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(salesforceRemoveAppsRequestRecord, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        salesforceRequestAccess: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/salesforce_request_access`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} instanceId 
         * @param {SalesforceRevokeTokensRequestRecord} salesforceRevokeTokensRequestRecord 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        salesforceRevokeTokens: async (instanceId: string, salesforceRevokeTokensRequestRecord: SalesforceRevokeTokensRequestRecord, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'instanceId' is not null or undefined
            assertParamExists('salesforceRevokeTokens', 'instanceId', instanceId)
            // verify required parameter 'salesforceRevokeTokensRequestRecord' is not null or undefined
            assertParamExists('salesforceRevokeTokens', 'salesforceRevokeTokensRequestRecord', salesforceRevokeTokensRequestRecord)
            const localVarPath = `/salesforce/instances/{instanceId}/revokeTokens`
                .replace(`{${"instanceId"}}`, encodeURIComponent(String(instanceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(salesforceRevokeTokensRequestRecord, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} instanceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        salesforceStatus: async (instanceId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'instanceId' is not null or undefined
            assertParamExists('salesforceStatus', 'instanceId', instanceId)
            const localVarPath = `/salesforce/instances/{instanceId}/status`
                .replace(`{${"instanceId"}}`, encodeURIComponent(String(instanceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * SalesforceApi - functional programming interface
 * @export
 */
export const SalesforceApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = SalesforceApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} instanceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async disconnect(instanceId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.disconnect(instanceId, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['SalesforceApi.disconnect']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {string} instanceId 
         * @param {string} integrationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getIntegration(instanceId: string, integrationId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SalesforceAppMenuItemRecord>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getIntegration(instanceId, integrationId, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['SalesforceApi.getIntegration']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listInstances(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<SalesforceInstanceRecord>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listInstances(options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['SalesforceApi.listInstances']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {string} instanceId 
         * @param {boolean} [lightMode] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listIntegrations(instanceId: string, lightMode?: boolean, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SalesforceIntegrationsAndActivityRecord>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listIntegrations(instanceId, lightMode, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['SalesforceApi.listIntegrations']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {string} instanceId 
         * @param {SalesforceInstallAppsRequestRecord} salesforceInstallAppsRequestRecord 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async salesforceInstallApps(instanceId: string, salesforceInstallAppsRequestRecord: SalesforceInstallAppsRequestRecord, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.salesforceInstallApps(instanceId, salesforceInstallAppsRequestRecord, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['SalesforceApi.salesforceInstallApps']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {string} code 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async salesforceOauthCallback(code: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.salesforceOauthCallback(code, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['SalesforceApi.salesforceOauthCallback']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {string} instanceId 
         * @param {SalesforceRemoveAppsRequestRecord} salesforceRemoveAppsRequestRecord 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async salesforceRemoveApps(instanceId: string, salesforceRemoveAppsRequestRecord: SalesforceRemoveAppsRequestRecord, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.salesforceRemoveApps(instanceId, salesforceRemoveAppsRequestRecord, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['SalesforceApi.salesforceRemoveApps']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async salesforceRequestAccess(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.salesforceRequestAccess(options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['SalesforceApi.salesforceRequestAccess']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {string} instanceId 
         * @param {SalesforceRevokeTokensRequestRecord} salesforceRevokeTokensRequestRecord 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async salesforceRevokeTokens(instanceId: string, salesforceRevokeTokensRequestRecord: SalesforceRevokeTokensRequestRecord, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.salesforceRevokeTokens(instanceId, salesforceRevokeTokensRequestRecord, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['SalesforceApi.salesforceRevokeTokens']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {string} instanceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async salesforceStatus(instanceId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SalesforceStatusRecord>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.salesforceStatus(instanceId, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['SalesforceApi.salesforceStatus']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
    }
};

/**
 * SalesforceApi - factory interface
 * @export
 */
export const SalesforceApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = SalesforceApiFp(configuration)
    return {
        /**
         * 
         * @param {string} instanceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        disconnect(instanceId: string, options?: any): AxiosPromise<void> {
            return localVarFp.disconnect(instanceId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} instanceId 
         * @param {string} integrationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getIntegration(instanceId: string, integrationId: string, options?: any): AxiosPromise<SalesforceAppMenuItemRecord> {
            return localVarFp.getIntegration(instanceId, integrationId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listInstances(options?: any): AxiosPromise<Array<SalesforceInstanceRecord>> {
            return localVarFp.listInstances(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} instanceId 
         * @param {boolean} [lightMode] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listIntegrations(instanceId: string, lightMode?: boolean, options?: any): AxiosPromise<SalesforceIntegrationsAndActivityRecord> {
            return localVarFp.listIntegrations(instanceId, lightMode, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} instanceId 
         * @param {SalesforceInstallAppsRequestRecord} salesforceInstallAppsRequestRecord 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        salesforceInstallApps(instanceId: string, salesforceInstallAppsRequestRecord: SalesforceInstallAppsRequestRecord, options?: any): AxiosPromise<boolean> {
            return localVarFp.salesforceInstallApps(instanceId, salesforceInstallAppsRequestRecord, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} code 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        salesforceOauthCallback(code: string, options?: any): AxiosPromise<void> {
            return localVarFp.salesforceOauthCallback(code, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} instanceId 
         * @param {SalesforceRemoveAppsRequestRecord} salesforceRemoveAppsRequestRecord 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        salesforceRemoveApps(instanceId: string, salesforceRemoveAppsRequestRecord: SalesforceRemoveAppsRequestRecord, options?: any): AxiosPromise<boolean> {
            return localVarFp.salesforceRemoveApps(instanceId, salesforceRemoveAppsRequestRecord, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        salesforceRequestAccess(options?: any): AxiosPromise<void> {
            return localVarFp.salesforceRequestAccess(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} instanceId 
         * @param {SalesforceRevokeTokensRequestRecord} salesforceRevokeTokensRequestRecord 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        salesforceRevokeTokens(instanceId: string, salesforceRevokeTokensRequestRecord: SalesforceRevokeTokensRequestRecord, options?: any): AxiosPromise<boolean> {
            return localVarFp.salesforceRevokeTokens(instanceId, salesforceRevokeTokensRequestRecord, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} instanceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        salesforceStatus(instanceId: string, options?: any): AxiosPromise<SalesforceStatusRecord> {
            return localVarFp.salesforceStatus(instanceId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * SalesforceApi - object-oriented interface
 * @export
 * @class SalesforceApi
 * @extends {BaseAPI}
 */
export class SalesforceApi extends BaseAPI {
    /**
     * 
     * @param {string} instanceId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SalesforceApi
     */
    public disconnect(instanceId: string, options?: RawAxiosRequestConfig) {
        return SalesforceApiFp(this.configuration).disconnect(instanceId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} instanceId 
     * @param {string} integrationId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SalesforceApi
     */
    public getIntegration(instanceId: string, integrationId: string, options?: RawAxiosRequestConfig) {
        return SalesforceApiFp(this.configuration).getIntegration(instanceId, integrationId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SalesforceApi
     */
    public listInstances(options?: RawAxiosRequestConfig) {
        return SalesforceApiFp(this.configuration).listInstances(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} instanceId 
     * @param {boolean} [lightMode] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SalesforceApi
     */
    public listIntegrations(instanceId: string, lightMode?: boolean, options?: RawAxiosRequestConfig) {
        return SalesforceApiFp(this.configuration).listIntegrations(instanceId, lightMode, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} instanceId 
     * @param {SalesforceInstallAppsRequestRecord} salesforceInstallAppsRequestRecord 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SalesforceApi
     */
    public salesforceInstallApps(instanceId: string, salesforceInstallAppsRequestRecord: SalesforceInstallAppsRequestRecord, options?: RawAxiosRequestConfig) {
        return SalesforceApiFp(this.configuration).salesforceInstallApps(instanceId, salesforceInstallAppsRequestRecord, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} code 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SalesforceApi
     */
    public salesforceOauthCallback(code: string, options?: RawAxiosRequestConfig) {
        return SalesforceApiFp(this.configuration).salesforceOauthCallback(code, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} instanceId 
     * @param {SalesforceRemoveAppsRequestRecord} salesforceRemoveAppsRequestRecord 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SalesforceApi
     */
    public salesforceRemoveApps(instanceId: string, salesforceRemoveAppsRequestRecord: SalesforceRemoveAppsRequestRecord, options?: RawAxiosRequestConfig) {
        return SalesforceApiFp(this.configuration).salesforceRemoveApps(instanceId, salesforceRemoveAppsRequestRecord, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SalesforceApi
     */
    public salesforceRequestAccess(options?: RawAxiosRequestConfig) {
        return SalesforceApiFp(this.configuration).salesforceRequestAccess(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} instanceId 
     * @param {SalesforceRevokeTokensRequestRecord} salesforceRevokeTokensRequestRecord 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SalesforceApi
     */
    public salesforceRevokeTokens(instanceId: string, salesforceRevokeTokensRequestRecord: SalesforceRevokeTokensRequestRecord, options?: RawAxiosRequestConfig) {
        return SalesforceApiFp(this.configuration).salesforceRevokeTokens(instanceId, salesforceRevokeTokensRequestRecord, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} instanceId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SalesforceApi
     */
    public salesforceStatus(instanceId: string, options?: RawAxiosRequestConfig) {
        return SalesforceApiFp(this.configuration).salesforceStatus(instanceId, options).then((request) => request(this.axios, this.basePath));
    }
}

