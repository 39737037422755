import {
  ActionIcon,
  Anchor,
  Avatar,
  Badge,
  Center,
  Col,
  Container,
  Grid,
  Group,
  HoverCard,
  List,
  Loader,
  Notification,
  ScrollArea,
  SimpleGrid,
  Stack,
  Table,
  Tabs,
  Text,
  TextInput,
  Tooltip,
  createStyles,
  useMantineTheme,
} from "@mantine/core";
import {
  IconCheck,
  IconExclamationMark,
  IconFocusCentered,
  IconInfoCircle,
  IconPackages,
  IconPackgeExport,
  IconPlugConnected,
  IconPlugConnectedX,
  IconSearch,
  IconShieldCheck,
  IconShieldChevron,
  IconShieldLock,
  IconShieldX,
  IconX,
} from "@tabler/icons";
import { AxiosError } from "axios";
import { format, parse } from "fecha";
import { useState } from "react";
import { useQuery } from "react-query";
import {
  SalesforceApi,
  SalesforceConnectedAppOauthScopeRecord,
  SalesforceInstanceRecord,
  SalesforceOauthTokenRecord,
} from "../../api/generated";
import {
  DISPLAY_DATETIME_FORMAT,
  VANTYR_API_DATETIME_FORMAT,
} from "../../constants/constants";
import { colorAvatar } from "../../misc/mixins_mantine";
import { formatBytes } from "../../misc/toolkit_common";
import { AppsTree } from "../reactflow/AppsTree";
import TableDataFlowSalesforceIntegration from "./TableDataFlowSalesforceIntegration";
import Oops from "../common/Oops";
import PillTabs, { PillTabConfig } from "../common/PillTabs";
import TableDataExposureSalesforceIntegration from "./TableDataExposureSalesforceIntegration";
import { demoInstanceFullRecord, demoMode } from "../../tooling/demo";

const badgeColorByRisk = ["gray.6", "orange.6", "red.6"];
const badgeLabelByRisk = ["Low risk", "Medium risk", "High risk"];

const tabs = [
  {
    label: "Summary",
    value: "summary",
    icon: IconFocusCentered,
  },
  {
    label: "Exposure",
    value: "data-exposure",
    icon: IconPackages,
  },
  {
    label: "Data Flow",
    value: "data-movement",
    icon: IconPackgeExport,
  },
  {
    label: "Sessions",
    value: "sessions",
    icon: IconPlugConnected,
  },
  {
    label: "Permissions",
    value: "scopes",
    icon: IconShieldLock,
  },
] as PillTabConfig[];

const useStyles = createStyles((theme) => ({
  rowSelected: {
    backgroundColor:
      theme.colorScheme === "dark"
        ? theme.fn.rgba(theme.colors[theme.primaryColor][7], 0.2)
        : theme.colors[theme.primaryColor][0],
  },

  tableHeader: {
    backgroundColor:
      theme.colorScheme === "dark"
        ? theme.fn.rgba(theme.colors["gray"][8], 0.2)
        : theme.colors["gray"][0],
  },

  th: {
    padding: "0 !important",
  },

  control: {
    width: "100%",
    padding: `${theme.spacing.xs} ${theme.spacing.md}`,

    "&:hover": {
      backgroundColor:
        theme.colorScheme === "dark"
          ? theme.colors.dark[6]
          : theme.colors.gray[0],
    },
  },

  icon: {
    width: 21,
    height: 21,
  },
}));

const TabsSalesforceIntegration = ({
  nodeData,
  startingActiveTab,
  setActiveTab,
  instance,
}: {
  nodeData: AppsTree;
  startingActiveTab: string;
  setActiveTab: (tab: string) => void;
  instance: SalesforceInstanceRecord;
}) => {
  const theme = useMantineTheme();
  const activeTab = startingActiveTab || "summary";
  const darkTheme = theme.colorScheme === "dark";

  const { classes, cx } = useStyles();

  const [tokenFilter, setTokenFilter] = useState<string>("");

  // useQuery to fetch integration data
  const { isError, error, isLoading, data } = useQuery(
    `integration_${nodeData.id}`,
    async () => {
      if (demoMode) {
        return demoInstanceFullRecord.integrations?.find(
          (i) => i.id === nodeData.id
        );
      }

      const rsp = await new SalesforceApi().getIntegration(
        instance.instanceId!,
        nodeData.id!
      );
      return rsp.data;
    },
    {
      staleTime: 10 * 60 * 1000,
    }
  );

  // if loading
  if (isLoading) {
    return (
      <Container fluid style={{ height: "100%", width: "100%" }}>
        <Center style={{ height: "500px" }}>
          <Loader color="gray" variant="dots" />
        </Center>
      </Container>
    );
  }

  // if error
  if (isError || error) {
    return <Oops />;
  }

  nodeData.oauthTokens = data!.oauthTokens!;
  nodeData.queriedEntities = data!.queriedEntities!;
  nodeData.classifiedFields = data!.classifiedFields!;

  // Inner function for mapping fetched oauth tokens to table rows
  function mapSessionsToRows(data: SalesforceOauthTokenRecord[]) {
    return data
      ?.filter(
        (token) =>
          token.userName?.toLowerCase().includes(tokenFilter) ||
          token.userProfileName?.toLowerCase().includes(tokenFilter)
      )
      .sort((a, b) => {
        if (a.lastUsedOn && b.lastUsedOn) {
          return (
            parse(b.lastUsedOn!, VANTYR_API_DATETIME_FORMAT)!.getTime() -
            parse(a.lastUsedOn!, VANTYR_API_DATETIME_FORMAT)!.getTime()
          );
        } else {
          return 0;
        }
      })
      .map((token) => {
        return (
          <tr key={token.id}>
            <td align="center">
              <Avatar
                radius="xl"
                size="sm"
                {...colorAvatar(token.userName || " ")}
              />
            </td>
            <td align="left">{token.userName}</td>
            <td align="left">{token.userProfileName}</td>
            <td align="left">
              {!token.createdOn
                ? " - "
                : format(
                    parse(token.createdOn!, VANTYR_API_DATETIME_FORMAT)!,
                    DISPLAY_DATETIME_FORMAT
                  )}
            </td>
            <td align="left">
              {!token.lastUsedOn
                ? " - "
                : format(
                    parse(token.lastUsedOn!, VANTYR_API_DATETIME_FORMAT)!,
                    DISPLAY_DATETIME_FORMAT
                  )}
            </td>
            <td align="right" style={{ paddingRight: "20px" }}>
              {token.useCount ? token.useCount : " - "}
            </td>
          </tr>
        );
      });
  }

  const sessionRows = mapSessionsToRows(data?.oauthTokens!);

  const piiCount = data!.classifiedFields?.reduce((acc, cur) => {
    return acc + (cur.split("::")[2]?.includes("PII") ? 1 : 0);
  }, 0);

  const phiCount = data!.classifiedFields?.reduce((acc, cur) => {
    return acc + (cur.split("::")[2]?.includes("PHI") ? 1 : 0);
  }, 0);

  const sensitiveCount = data!.classifiedFields?.reduce((acc, cur) => {
    return acc + (cur.split("::")[2]?.includes("Sensitive") ? 1 : 0);
  }, 0);

  const pciCount = data!.classifiedFields?.reduce((acc, cur) => {
    return acc + (cur.split("::")[2]?.includes("PCI") ? 1 : 0);
  }, 0);

  return (
    <PillTabs
      tabs={tabs}
      activeTab={activeTab}
      setActiveTab={setActiveTab}
      narrowView
    >
      <Tabs.Panel value="summary" pt="xs">
        <Stack spacing="xl" my="xl">
          <SimpleGrid cols={1} spacing="xl" style={{ textAlign: "left" }}>
            {!data!.createdBy ? (
              <Group>
                <Avatar color="pink.6" radius="xl" size="md">
                  <IconExclamationMark stroke={1} />
                </Avatar>
                <Text size="sm">
                  <strong>Shadow integration</strong>
                  <br></br>Not installed by Salesforce admin
                </Text>
              </Group>
            ) : (
              <Group>
                <Avatar color="green.6" radius="xl" size="md">
                  <IconCheck stroke={1} />
                </Avatar>
                <Text size="sm">
                  <strong>Approved Integration</strong>
                  <br></br>Installed by {data!.createdBy} on{" "}
                  {format(
                    parse(data!.createdOn!, VANTYR_API_DATETIME_FORMAT)!,
                    DISPLAY_DATETIME_FORMAT
                  )}
                </Text>
              </Group>
            )}
            <Tooltip
              label=<Stack>
                {data!.riskLevelReason
                  ?.split(".")
                  .map((r) => r && <Text key={r}>{r}.</Text>)}
                <Text>
                  Risk score:{" "}
                  <strong>
                    {Math.max(0, Math.min(100, data!.riskScore!))}
                  </strong>
                </Text>
              </Stack>
              withArrow
              multiline
              width={300}
              color={theme.colors.dark[3]}
              transitionProps={{
                transition: "fade",
              }}
            >
              <Group align="left">
                {!data!.createdBy ? (
                  <Group>
                    <Avatar color="red.6" radius="xl" size="md">
                      <IconShieldX stroke={1} />
                    </Avatar>
                    <Text size="sm">
                      <strong>High Risk</strong>
                      <br></br>Access permissions are not visible
                    </Text>
                  </Group>
                ) : data!.riskLevel === 2 ? (
                  <Group>
                    <Avatar color="red.6" radius="xl" size="md">
                      <IconShieldX stroke={1} />
                    </Avatar>
                    <Text size="sm">
                      <strong>High Risk</strong>
                      <br></br>Based on granted permission level
                    </Text>
                  </Group>
                ) : data!.riskLevel === 1 ? (
                  <Group>
                    <Avatar color="orange.6" radius="xl" size="md">
                      <IconShieldChevron stroke={1} />
                    </Avatar>
                    <Text size="sm">
                      <strong>Medium Risk</strong>
                      <br></br>Based on granted permission level
                    </Text>
                  </Group>
                ) : (
                  <Group>
                    <Avatar color="gray.6" radius="xl" size="md">
                      <IconShieldCheck stroke={1} />
                    </Avatar>
                    <Text size="sm">
                      <strong>Low Risk</strong>
                      <br></br>Based on granted permission level
                    </Text>
                  </Group>
                )}
              </Group>
            </Tooltip>
            <Group align="left">
              {data!.oauthTokens!.length !== 0 ? (
                <Group>
                  <Avatar color="orange.6" radius="xl" size="md">
                    <IconPlugConnected stroke={1} />
                  </Avatar>
                  <Text size="sm">
                    <strong>{data!.oauthTokens!.length} open sessions</strong>
                    <br></br>On behalf of{" "}
                    {
                      [
                        ...new Set(
                          data!
                            .oauthTokens!.filter((x) => x.userIsActive)
                            .map((t: SalesforceOauthTokenRecord) => t.userName)
                        ),
                      ].length
                    }{" "}
                    authorized users{" "}
                  </Text>
                </Group>
              ) : (
                <Group>
                  <Avatar color="gray.6" radius="xl" size="md">
                    <IconPlugConnectedX stroke={1} />
                  </Avatar>
                  <Text size="sm">
                    <strong>Unused integration</strong>
                    <br></br>No active access tokens
                  </Text>
                </Group>
              )}
            </Group>
            <Group align="left">
              {!data!.queriedEntities || data!.queriedEntities.length === 0 ? (
                <Group>
                  <Avatar color="gray.6" radius="xl" size="md">
                    <IconX stroke={1} />
                  </Avatar>
                  <Text size="sm">
                    <strong>No data movement</strong>
                    <br></br>No data flow in the observed period
                  </Text>
                </Group>
              ) : (
                <Group>
                  <Avatar
                    color="indigo.6"
                    radius="xl"
                    size="md"
                    style={{ alignSelf: "start" }}
                  >
                    <IconPackgeExport stroke={1} />
                  </Avatar>
                  <Stack spacing={0}>
                    <Text size="sm">
                      <strong>Classified data movement</strong>
                      <br />
                      {piiCount! > 0 && `${piiCount} PII`}
                      {piiCount! > 0 &&
                        phiCount! + sensitiveCount! + pciCount! > 0 &&
                        ", "}
                      {phiCount! > 0 && `${phiCount} PHI`}
                      {phiCount! > 0 && sensitiveCount! + pciCount! > 0 && ", "}
                      {pciCount! > 0 && `${pciCount} PCI`}
                      {pciCount! > 0 && sensitiveCount! > 0 && ", "}
                      {sensitiveCount! > 0 &&
                        `${sensitiveCount} Business Sensitive`}{" "}
                      data types pulled
                    </Text>
                  </Stack>
                </Group>
              )}
            </Group>
          </SimpleGrid>

          {/* {!data!.createdBy ? (
            <SimpleGrid cols={1} spacing="xl" py="xl">
              <Notification
                title={
                  <Group>
                    <Text weight={600}>Not approved by a Salesforce admin</Text>
                    <HoverCard
                      width="300px"
                      position="top"
                      withArrow
                      shadow="md"
                    >
                      <HoverCard.Dropdown sx={{ pointerEvents: "none" }}>
                        <Text size="sm">
                          Salesforce can not access and manage the details of
                          shadow integrations, until explicitly installed by a
                          Salesforce administator via the Salesforce Setup UI.
                        </Text>
                      </HoverCard.Dropdown>
                      <HoverCard.Target>
                        <Center>
                          <IconInfoCircle size={14} stroke={1} />
                        </Center>
                      </HoverCard.Target>
                    </HoverCard>
                  </Group>
                }
                color="pink.6"
                withCloseButton={false}
                icon={
                  <Avatar color="pink.6" radius="xl" size="md">
                    <IconExclamationMark stroke={1} />
                  </Avatar>
                }
              ></Notification>
              <Notification
                title={
                  <Group>
                    <Text weight={600}>Unknown permissions</Text>
                    <HoverCard
                      width="300px"
                      position="top"
                      withArrow
                      shadow="md"
                    >
                      <HoverCard.Dropdown sx={{ pointerEvents: "none" }}>
                        <Text size="sm">
                          Shadow integrations have not been approved / installed
                          by a Salesforce administator. The permissions granted
                          to such integrations are not known.
                        </Text>
                      </HoverCard.Dropdown>
                      <HoverCard.Target>
                        <Center>
                          <IconInfoCircle size={14} stroke={1} />
                        </Center>
                      </HoverCard.Target>
                    </HoverCard>
                  </Group>
                }
                color="pink.6"
                withCloseButton={false}
                icon={
                  <Avatar color="pink.6" radius="xl" size="md">
                    <IconExclamationMark stroke={1} />
                  </Avatar>
                }
              ></Notification>
            </SimpleGrid>
          ) : data!.riskLevel === 2 ? (
            <></>
          ) : // <Tooltip
          null} */}
          <Stack spacing="xs" my="xs" justify="flex-start">
            <Text size="sm" pb="md" italic={!data!.description}>
              <strong>Description:</strong>
              {data!.description
                ? ` ${data!.description}`
                : " No description provided by integration vendor"}
            </Text>
            <Grid gutter="xs">
              <Col span={4}>
                <Text size="xs" color="dimmed">
                  Integration type
                </Text>
              </Col>
              <Col span={8}>
                <Text size="xs">{data?.type}</Text>
              </Col>

              <Col span={4}>
                <Text size="xs" color="dimmed">
                  Webpage
                </Text>
              </Col>
              <Col span={8}>
                <Anchor
                  style={{ lineHeight: 1 }}
                  size="xs"
                  href={data!.infoUrl || "#"}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {data!.infoUrl || " - "}
                </Anchor>
              </Col>

              <Col span={4}>
                <Text size="xs" color="dimmed">
                  Admin authorization only
                </Text>
              </Col>
              <Col span={8}>
                <Text size="xs">
                  {data?.isUsingAdminAuthOnly ? "Yes" : "No"}
                </Text>
              </Col>
            </Grid>
          </Stack>
        </Stack>
      </Tabs.Panel>

      <Tabs.Panel value="scopes" pt="xs">
        <Stack spacing="xl" my="xl">
          {!data?.oauthScopes || data.oauthScopes.length === 0 ? (
            <Group>
              <Avatar color="pink.6" radius="xl" size="md">
                <IconShieldX stroke={1} />
              </Avatar>
              <Text size="sm">
                <strong>Unknown permissions</strong>
                <br></br>Salesforce can not access the details of shadow
                integrations!
              </Text>
            </Group>
          ) : (
            <List
              spacing={"sm"}
              defaultValue="openid"
              style={{ listStyleType: "none" }}
            >
              {data!.oauthScopes!.map(
                (scope: SalesforceConnectedAppOauthScopeRecord) => (
                  <List.Item key={scope.id + "aa"} value={scope.id!}>
                    <Stack spacing={0}>
                      <Group position="left">
                        <Text size="sm">{scope.label}</Text>
                        <Badge
                          radius="xl"
                          variant="light"
                          size="xs"
                          color={badgeColorByRisk[scope.riskLevel!]}
                        >
                          {badgeLabelByRisk[scope.riskLevel!]}
                        </Badge>
                      </Group>
                      <Text color="dimmed" size="xs">
                        {scope.description}
                      </Text>
                    </Stack>
                  </List.Item>
                )
              )}
            </List>
          )}
        </Stack>
      </Tabs.Panel>

      <Tabs.Panel value="details" pt="xl">
        <Stack px="xs" spacing="xl" pt="xl">
          <Text size="sm" pb="md" px="xs" italic={!data!.description}>
            {data!.description ||
              "No description provided by integration vendor"}
          </Text>
          <SimpleGrid cols={1} spacing="xs" pt="xs" px="xs">
            <Group position="apart" py={0}>
              <Text size="xs" color="dimmed">
                Integration type
              </Text>
              <Text size="xs">{data?.type}</Text>
            </Group>
            <Group position="apart" py={0}>
              <Text size="xs" color="dimmed">
                Installed by
              </Text>
              <Text size="xs">{data?.createdBy}</Text>
            </Group>
            <Group position="apart" py={0}>
              <Text size="xs" color="dimmed">
                Installed on
              </Text>
              <Text size="xs">
                {!data?.createdOn
                  ? " - "
                  : format(
                      parse(data!.createdOn!, VANTYR_API_DATETIME_FORMAT)!,
                      DISPLAY_DATETIME_FORMAT
                    ) ||
                    data!.createdOn ||
                    " - "}
              </Text>
            </Group>
            <Group position="apart" py={0}>
              <Text size="xs" color="dimmed">
                Webpage
              </Text>
              <Anchor
                style={{ lineHeight: 1 }}
                size="xs"
                href={data!.infoUrl || "#"}
                target="_blank"
                rel="noopener noreferrer"
              >
                {data!.infoUrl || " - "}
              </Anchor>
            </Group>
            <Group position="apart" py={0}>
              <Text size="xs" color="dimmed">
                Admin authorization only
              </Text>
              <Text size="xs">{data?.isUsingAdminAuthOnly ? "Yes" : "No"}</Text>
            </Group>
            <Group position="apart" py={0}>
              <Text size="xs" color="dimmed">
                Users granted access
              </Text>
              <Text size="xs">
                {[...new Set(data!.oauthTokens!.map((t) => t.userName))].length}
              </Text>
            </Group>
          </SimpleGrid>
        </Stack>
      </Tabs.Panel>

      <Tabs.Panel value="data-exposure" pt="xs">
        <Stack spacing="xl" my="xl">
          <Container style={{ width: "100%", textAlign: "left" }} fluid p={0}>
            <Group>
              <Avatar color="indigo.6" radius="xl" size="md">
                <IconPackages stroke={1} />
              </Avatar>
              {data!.exposedFieldsCountClassifiedTotal! > 0 ? (
                <Text size="sm">
                  <strong>
                    Up to{" "}
                    {data!.exposedFieldsCountClassifiedTotal!.toLocaleString()}{" "}
                    classified data points exposed
                  </strong>
                  <br></br>
                  Exposed data points:{" "}
                  {data!.exposedFieldsCountPII! > 0 &&
                    `${data!.exposedFieldsCountPII?.toLocaleString()} PII`}
                  {data!.exposedFieldsCountPII! > 0 &&
                    data!.exposedFieldsCountPHI! +
                      data!.exposedFieldsCountSensitive! +
                      data!.exposedFieldsCountPCI! >
                      0 &&
                    ", "}
                  {data!.exposedFieldsCountPHI! > 0 &&
                    `${data!.exposedFieldsCountPHI?.toLocaleString()} PHI`}
                  {data!.exposedFieldsCountPHI! > 0 &&
                    data!.exposedFieldsCountSensitive! +
                      data!.exposedFieldsCountPCI! >
                      0 &&
                    ", "}
                  {data!.exposedFieldsCountPCI! > 0 &&
                    `${data!.exposedFieldsCountPCI?.toLocaleString()} PCI`}
                  {data!.exposedFieldsCountPCI! > 0 &&
                    data!.exposedFieldsCountSensitive! > 0 &&
                    ", "}
                  {data!.exposedFieldsCountSensitive! > 0 &&
                    `${data!.exposedFieldsCountSensitive!.toLocaleString()} Business Sensitive`}{" "}
                  and {data!.exposedFieldsCountUnclassified!.toLocaleString()}{" "}
                  unclassified
                </Text>
              ) : (
                <Text size="sm">
                  <strong>No classified data currently exposed</strong>
                </Text>
              )}
            </Group>
            <TableDataExposureSalesforceIntegration data={data!} />
          </Container>
        </Stack>
      </Tabs.Panel>

      <Tabs.Panel value="data-movement" pt="xs">
        <Stack spacing="xl" my="xl">
          <Container style={{ width: "100%", textAlign: "left" }} fluid p={0}>
            <Group align="left">
              {!data!.queriedEntities || data!.queriedEntities.length === 0 ? (
                <Group>
                  <Avatar color="gray.6" radius="xl" size="md">
                    <IconX stroke={1} />
                  </Avatar>
                  <Text size="sm">
                    <strong>No data flow</strong>
                    <br></br>No data movement in the observed period
                  </Text>
                </Group>
              ) : (
                <Group position="apart">
                  <Group>
                    <Avatar color="indigo.6" radius="xl" size="md">
                      <IconPackgeExport stroke={1} />
                    </Avatar>
                    <Text size="sm">
                      <strong>
                        {formatBytes(data!.usageVolumeBytes!)} of Salesforce
                        data pulled
                      </strong>
                      <br></br>
                      Vantyr detected {piiCount! > 0 && `${piiCount} PII`}
                      {piiCount! > 0 &&
                        phiCount! + sensitiveCount! + pciCount! > 0 &&
                        ", "}
                      {phiCount! > 0 && `${phiCount} PHI`}
                      {phiCount! > 0 && sensitiveCount! + pciCount! > 0 && ", "}
                      {pciCount! > 0 && `${pciCount} PCI`}
                      {pciCount! > 0 && sensitiveCount! > 0 && ", "}
                      {sensitiveCount! > 0 &&
                        `${sensitiveCount} Business Sensitive`}{" "}
                      data types pulled
                    </Text>
                  </Group>
                </Group>
              )}
            </Group>
            <TableDataFlowSalesforceIntegration
              integration={data!}
            ></TableDataFlowSalesforceIntegration>
          </Container>
        </Stack>
      </Tabs.Panel>

      <Tabs.Panel value="sessions" pt="xs">
        <Stack my="xl" spacing="xl">
          <Container p={0} fluid style={{ width: "100%", textAlign: "left" }}>
            <Group position="apart">
              <Group align="left">
                {data!.oauthTokens!.length !== 0 ? (
                  <Group>
                    <Avatar color="orange.6" radius="xl" size="md">
                      <IconPlugConnected stroke={1} />
                    </Avatar>
                    <Text size="sm">
                      <strong>{data!.oauthTokens!.length} open sessions</strong>
                      <br></br>On behalf of{" "}
                      {
                        [
                          ...new Set(
                            data!
                              .oauthTokens!.filter((x) => x.userIsActive)
                              .map((t) => t.userName)
                          ),
                        ].length
                      }{" "}
                      active users
                    </Text>
                  </Group>
                ) : (
                  <Group>
                    <Avatar color="gray.6" radius="xl" size="md">
                      <IconPlugConnectedX stroke={1} />
                    </Avatar>
                    <Text size="sm">
                      <strong>Unused integration</strong>
                      <br></br>No user has granted access rights to this
                      integration at the present moment
                    </Text>
                  </Group>
                )}
              </Group>
              {data!.oauthTokens!.length !== 0 && (
                <Group>
                  <TextInput
                    placeholder="Search"
                    style={{ width: 200 }}
                    icon={<IconSearch size={16} stroke={1} />}
                    value={tokenFilter}
                    radius="xl"
                    onChange={(val) => setTokenFilter(val.currentTarget.value)}
                  />
                </Group>
              )}
            </Group>
          </Container>
          {data!.oauthTokens!.length !== 0 && (
            <ScrollArea pb="md" pt={0}>
              <Table
                verticalSpacing="xs"
                fontSize="xs"
                style={{ tableLayout: "fixed" }}
              >
                <thead>
                  <tr className={cx({ [classes.tableHeader]: true })}>
                    <th style={{ width: 40, textAlign: "center" }}></th>
                    <th>User</th>
                    <th style={{ width: 160, textAlign: "left" }}>Profile</th>
                    <th style={{ width: 100, textAlign: "left" }}>
                      Created on
                    </th>
                    <th style={{ width: 100, textAlign: "left" }}>Last used</th>
                    <th
                      style={{
                        width: 90,
                        paddingRight: "20px",
                        textAlign: "right",
                      }}
                    >
                      Use count
                    </th>
                  </tr>
                </thead>
                <tbody>{sessionRows}</tbody>
              </Table>
            </ScrollArea>
          )}
        </Stack>
      </Tabs.Panel>
    </PillTabs>
  );

  function tabFontColor(thisTab: string) {
    return activeTab === thisTab ? "blue.6" : darkTheme ? "gray.5" : "gray.9";
  }
};

export default TabsSalesforceIntegration;
