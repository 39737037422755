import { Badge, Group, List, Stack, Text } from "@mantine/core";
import { SlackIntegration, SlackScope } from "../../misc/toolkit_slack";
import { SummarySlackIntegrationNoScopes } from "./SummarySlackIntegration";

interface ListSlackScopesProps {
  integration: SlackIntegration;
}

const ListSlackScopes = ({ integration }: ListSlackScopesProps) => {
  return (
    <>
      <SummarySlackIntegrationNoScopes integration={integration} />
      <List
        spacing={"sm"}
        defaultValue="openid"
        style={{ listStyleType: "none" }}
        p={0}
        m={0}
      >
        {integration?.scopes?.map((scope: SlackScope) => (
          <List.Item key={scope.name} value={scope.name!}>
            <Stack spacing={0}>
              <Group position="left" spacing="xs">
                <Text size="sm">{scope.name}</Text>
                {scope.asBot ? (
                  <Badge radius="xl" variant="light" size="xs" color="blue">
                    Bot
                  </Badge>
                ) : (
                  <Badge radius="xl" variant="light" size="xs" color="teal">
                    User
                  </Badge>
                )}
                {scope.isAdmin && (
                  <Badge radius="xl" variant="light" size="xs" color="violet">
                    Admin
                  </Badge>
                )}
                {scope.isSensitive && (
                  <Badge radius="xl" variant="light" size="xs" color="pink">
                    Sensitive
                  </Badge>
                )}
              </Group>
              <Text color="dimmed" size="xs">
                {scope.description}
              </Text>
            </Stack>
          </List.Item>
        ))}
      </List>
    </>
  );
};

export default ListSlackScopes;
