import {
  ActionIcon,
  Avatar,
  Badge,
  Button,
  Center,
  Container,
  createStyles,
  Group,
  Loader,
  Paper,
  PasswordInput,
  ScrollArea,
  Select,
  Table,
  Text,
  TextInput,
  useMantineTheme,
} from "@mantine/core";
import { useForm } from "@mantine/form";
import { closeAllModals, openModal } from "@mantine/modals";
import { showNotification } from "@mantine/notifications";
import {
  IconPencil,
  IconPlus,
  IconSearch,
  IconUsers,
  IconX,
} from "@tabler/icons";
import { AxiosError } from "axios";
import { useState } from "react";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { AccessApi, UserRecord, UserRegisterRecord } from "../../api/generated";
import { colorAvatar, DefaultSelectItem } from "../../misc/mixins_mantine";
import { useAccessStore } from "../../state/store";
import Oops from "../common/Oops";
import { demoMode, demoUsers } from "../../tooling/demo";

const useStyles = createStyles((theme) => ({
  tableHeader: {
    backgroundColor:
      theme.colorScheme === "dark"
        ? theme.fn.rgba(theme.colors["gray"][8], 0.2)
        : theme.colors["gray"][0],
  },
}));

export default function ScreenTeam() {
  const { classes, cx } = useStyles();
  const theme = useMantineTheme();

  const loggedInUserId = useAccessStore((state) => state.userId);
  const loggedInUserRole = useAccessStore((state) => state.userRole);
  const loggedInUserIsNotAdmin = loggedInUserRole === "Viewer";

  const [filter, setFilter] = useState("");

  // Inner function for mapping fetched data to table rows
  function mapToTableRows(data: UserRecord[]) {
    return data
      ?.filter(
        (user) =>
          user.fullName?.toLowerCase().includes(filter) ||
          user.email?.toLowerCase().includes(filter)
      )
      .map((user) => {
        return (
          <tr key={user.id}>
            <td align="center">
              <Avatar
                radius="xl"
                size="sm"
                {...colorAvatar(user.fullName || " ")}
              />
            </td>
            <td align="left">{user.fullName}</td>
            <td align="left">
              <Text>{user.email}</Text>
            </td>
            <td align="left">
              <Badge
                radius="xl"
                color={user.role === "Admin" ? "violet" : "cyan"}
              >
                {user.role}
              </Badge>
            </td>
            <td align="center">
              <ActionIcon
                color="blue"
                variant="transparent"
                onClick={() => openEditUserModal(user)}
                disabled={loggedInUserIsNotAdmin}
              >
                <IconPencil stroke={1} />
              </ActionIcon>
            </td>
            <td align="left">
              {user.id !== loggedInUserId && (
                <ActionIcon
                  variant="transparent"
                  color="red"
                  disabled={loggedInUserIsNotAdmin}
                  onClick={() => openDeleteUserModal(user)}
                >
                  <IconX stroke={1} />
                </ActionIcon>
              )}
            </td>
          </tr>
        );
      });
  }

  // Modal for registering a new user
  const openAddUserModal = () =>
    openModal({
      title: "Register a new Vantyr user",
      centered: true,
      overlayProps: {
        color:
          theme.colorScheme === "dark"
            ? theme.colors.dark[9]
            : theme.colors.gray[2],
        opacity: 0.55,
        blur: 3,
      },
      shadow: "xl",
      radius: "md",
      children: <AddUserForm></AddUserForm>,
    });

  // Modal for editing a user
  const openEditUserModal = (user: UserRecord) =>
    openModal({
      title: "Edit Vantyr user",
      centered: true,
      overlayProps: {
        color:
          theme.colorScheme === "dark"
            ? theme.colors.dark[9]
            : theme.colors.gray[2],
        opacity: 0.55,
        blur: 3,
      },
      shadow: "xl",
      radius: "md",
      children: <EditUserForm user={user}></EditUserForm>,
    });

  // Modal for deleting a user
  const openDeleteUserModal = (user: UserRecord) =>
    openModal({
      title: "Delete Vantyr user",
      centered: true,
      overlayProps: {
        color:
          theme.colorScheme === "dark"
            ? theme.colors.dark[9]
            : theme.colors.gray[2],
        opacity: 0.55,
        blur: 3,
      },
      shadow: "xl",
      radius: "md",
      children: <DeleteUserForm user={user}></DeleteUserForm>,
    });

  // useQuery to fetch users
  const { isError, error, isLoading, data } = useQuery("users", async () => {
    // if (demoMode) {
    //   return demoUsers;
    // }
    const accessApi = new AccessApi();
    const rsp = await accessApi.getUsers();
    return rsp.data;
  });

  // if loading
  if (isLoading) {
    return (
      <Center style={{ width: "100%", height: "100%" }}>
        <Loader color="gray" variant="dots"></Loader>
      </Center>
    );
  }

  // if error
  if (isError || error) {
    return <Oops />;
  }

  // if all OK
  const rows = mapToTableRows(data!);

  return (
    <Paper
      shadow="md"
      m="xl"
      style={{ minWidth: 600, maxWidth: 1500, width: "auto" }}
    >
      <Container p="md" fluid>
        <Group position="apart" px="md">
          <Group position="left">
            <IconUsers stroke={1} size="32" />
            <div>
              <Text align="left" size="sm">
                Vantyr users
              </Text>
              <Text size="xs" color="dimmed" align="left">
                {data?.length} registered users
              </Text>
            </div>
          </Group>
          <Group>
            <TextInput
              placeholder="Filter users"
              style={{ width: 200 }}
              icon={<IconSearch size={16} stroke={1} />}
              value={filter}
              onChange={(val) =>
                setFilter(val.currentTarget.value.toLowerCase())
              }
              radius="xl"
            />
            <Button
              leftIcon={<IconPlus size={16} />}
              variant="outline"
              disabled={loggedInUserIsNotAdmin}
              onClick={() => openAddUserModal()}
              radius="xl"
            >
              Add a user
            </Button>
          </Group>
        </Group>
      </Container>
      <ScrollArea>
        <Table
          verticalSpacing="xs"
          fontSize="xs"
          style={{ tableLayout: "fixed" }}
        >
          <thead>
            <tr className={cx({ [classes.tableHeader]: true })}>
              <th style={{ width: 60, textAlign: "center" }}></th>
              <th>User</th>
              <th>Email</th>
              <th style={{ width: 160 }}>Role</th>
              <th style={{ width: 60, textAlign: "center" }}></th>
              <th style={{ width: 60, textAlign: "center" }}></th>
            </tr>
          </thead>
          <tbody>{rows}</tbody>
        </Table>
      </ScrollArea>
    </Paper>
  );
}

export function AddUserForm() {
  const queryClient = useQueryClient();

  const addUserForm = useForm({
    initialValues: {
      email: "",
      fullName: "",
      password: "",
      role: "Viewer",
    } as UserRegisterRecord,
  });

  const { isLoading, isError, mutate } = useMutation(
    "registerUser",
    async (values: UserRegisterRecord) => {
      await new AccessApi().register(values);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries({ queryKey: ["users"] });
        closeAllModals();
      },
      onError: (error) => {
        console.log(error);
        queryClient.invalidateQueries({ queryKey: ["users"] });
        closeAllModals();
        showNotification({
          title: "Oops!",
          message: `Something went wrong! ${
            (error as unknown as AxiosError)?.message
          }`,
        });
      },
    }
  );

  if (isLoading) {
    return <div>Registering user ...</div>;
  }

  if (isError) {
    return <>Error</>;
  }

  return (
    <form
      autoComplete="off"
      onSubmit={addUserForm.onSubmit((values) => mutate(values))}
      onReset={() => closeAllModals()}
    >
      <TextInput
        label="Full name"
        placeholder="User's full name"
        required
        my="md"
        radius="xl"
        {...addUserForm.getInputProps("fullName")}
      />
      <TextInput
        label="Email"
        placeholder="User's email"
        required
        my="md"
        radius="xl"
        {...addUserForm.getInputProps("email")}
      />
      <PasswordInput
        autoComplete="new-password"
        label="Password"
        placeholder="User's password"
        required
        description="Password must be at least 6 characters long"
        my="md"
        radius="xl"
        {...addUserForm.getInputProps("password")}
      />
      <Select
        label="Role"
        placeholder="Select a role"
        required
        data={[
          {
            label: "Viewer",
            value: "Viewer",
            description:
              "Viewers can not manage users and connect SaaS instances",
          },
          {
            label: "Admin",
            value: "Admin",
            description: "Admins have unlimited access to the Vantyr dashboard",
          },
        ]}
        mt="md"
        mb="xl"
        radius="xl"
        // itemComponent={DefaultSelectItem}
        description="Viewers can not manage users and connect SaaS instances."
        {...addUserForm.getInputProps("role")}
      />
      <Group position="right">
        <Button type="reset" variant="default" radius="xl">
          Cancel
        </Button>
        <Button type="submit" variant="outline" radius="xl">
          Register
        </Button>
      </Group>
    </form>
  );
}

export function EditUserForm(props: { user: UserRecord }) {
  const queryClient = useQueryClient();
  const { user } = props;

  const editUserForm = useForm({
    initialValues: user,
  });

  const { isLoading, isError, mutate } = useMutation(
    "registerUser",
    async (values: UserRecord) => {
      await new AccessApi().updateUser(user.id!, values);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries({ queryKey: ["users"] });
        queryClient.invalidateQueries({ queryKey: ["userLoggedIn"] });
        closeAllModals();
      },
      onError: (error) => {
        console.log(error);
        queryClient.invalidateQueries({ queryKey: ["users"] });
        queryClient.invalidateQueries({ queryKey: ["userLoggedIn"] });
        closeAllModals();
        showNotification({
          title: "Oops!",
          message: `Something went wrong! ${
            (error as unknown as AxiosError)?.message
          }`,
        });
      },
    }
  );

  if (isLoading) {
    return <div>Updating user ...</div>;
  }

  if (isError) {
    return <>Error</>;
  }

  return (
    <form
      autoComplete="off"
      onSubmit={editUserForm.onSubmit((values) => mutate(values))}
      onReset={() => closeAllModals()}
    >
      <TextInput
        label="Full name"
        placeholder="User's full name"
        required
        my="md"
        radius="xl"
        {...editUserForm.getInputProps("fullName")}
      />
      <TextInput
        label="Email"
        placeholder="User's email"
        required
        radius="xl"
        {...editUserForm.getInputProps("email")}
      />
      <PasswordInput
        autoComplete="new-password"
        label="Password"
        placeholder="User's password"
        description="Password must be at least 6 characters long"
        my="md"
        radius="xl"
        {...editUserForm.getInputProps("password")}
      />
      <Select
        label="Role"
        placeholder="Select a role"
        required
        data={["Admin", "Viewer"]}
        mt="md"
        mb="xl"
        radius="xl"
        description="Viewers can not manage users and connect SaaS instances."
        {...editUserForm.getInputProps("role")}
        disabled
      />
      <Group position="right">
        <Button type="reset" variant="default" color="gray" radius="xl">
          Cancel
        </Button>
        <Button type="submit" variant="outline" radius="xl">
          Update
        </Button>
      </Group>
    </form>
  );
}

export function DeleteUserForm(props: { user: UserRecord }) {
  const queryClient = useQueryClient();
  const { user } = props;

  const { isLoading, isError, mutate } = useMutation(
    "deleteUser",
    async (values: UserRegisterRecord) => {
      await new AccessApi().deleteUser(user.id!);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries({ queryKey: ["users"] });
        closeAllModals();
      },
      onError: (error) => {
        console.log(error);
        queryClient.invalidateQueries({ queryKey: ["users"] });
        closeAllModals();
        showNotification({
          title: "Oops!",
          message: `Something went wrong! ${
            (error as unknown as AxiosError)?.message
          }`,
        });
      },
    }
  );

  if (isLoading) {
    return <div>Deleting user ...</div>;
  }

  if (isError) {
    return <>Error</>;
  }

  return (
    <form onSubmit={() => mutate(user)} onReset={() => closeAllModals()}>
      <Text>Are you sure you wish to delete {user.fullName}'s account?</Text>
      <Group position="right" mt="md">
        <Button type="reset" variant="default" color="gray">
          Cancel
        </Button>
        <Button type="submit" variant="outline" color="red">
          Delete
        </Button>
      </Group>
    </form>
  );
}
