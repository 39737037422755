import {
  Center,
  Container,
  Group,
  Loader,
  Paper,
  Stack,
  Text,
} from "@mantine/core";
import { useDebouncedState } from "@mantine/hooks";
import { useState } from "react";
import { useQuery } from "react-query";
import { useParams } from "react-router-dom";
import { SlackApi } from "../../api/generated";
import { useAppsStore } from "../../state/store";
import { demoInstanceSlack, demoMode } from "../../tooling/demo";
import Oops from "../common/Oops";
import SearchInput from "../common/SearchInput";
import FilterSlackInstances from "../slack/FilterSlackInstances";
import SlackInstanceSummary from "../slack/SlackInstanceSummary";
import { TableSlackIntegrations } from "../slack/TableIntegrationsSlack";

const ScreenSlackInstance = () => {
  const { instanceId } = useParams();

  const setSlackInstanceSelected = useAppsStore(
    (state) => state.setSlackInstanceSelected
  );

  const { isError, error, isLoading, data } = useQuery(
    `slack_integrations_${instanceId}`,
    async () => {
      if (demoMode) {
        return demoInstanceSlack;
      }

      const rsp = await new SlackApi().slackListIntegrations(instanceId!);
      return rsp.data;
    },
    {
      staleTime: 10 * 60 * 1000,
      refetchInterval: 10 * 60 * 1000,
    }
  );

  const [filterConditions, setFilterConditions] = useState<string[]>([]);
  const [searchCondition, setSearchCondition] = useDebouncedState<string>(
    "",
    200
  );

  const [activePage, setActivePage] = useState(1);

  const onSearch = (searchCondition: string) => {
    setActivePage(1);
    setSearchCondition(searchCondition);
  };

  const onFilter = (filterConditions: string[]) => {
    setActivePage(1);
    setFilterConditions(filterConditions);
  };

  // if loading
  if (isLoading) {
    return (
      <Center style={{ width: "100%", height: "100%" }}>
        <Stack align="center">
          <Text size="sm" my="md">
            Detecting integrations and activity. Hold on, this may take a minute
            ...
          </Text>
          <Loader color="gray" variant="dots"></Loader>
        </Stack>
      </Center>
    );
  }

  // if error
  if (isError || error) {
    return <Oops />;
  }

  const instanceActivityRecord = data!;

  setSlackInstanceSelected(instanceActivityRecord.instance || null);

  // if all OK
  console.log("Oauth Stats:");
  // console.log(instanceActivityRecord?.oauthStats);

  return (
    <>
      <Paper
        shadow="xl"
        m="xl"
        style={{ minWidth: 1000, maxWidth: 1600, width: "auto" }}
      >
        <Container p="md" fluid>
          <Group position="apart" pl="md">
            <SlackInstanceSummary instance={instanceActivityRecord!} />
            <Group>
              <SearchInput
                onSearch={(searchCondition) => onSearch(searchCondition)}
              />
              <FilterSlackInstances
                onFilter={(filterConditions) => onFilter(filterConditions)}
              />
            </Group>
          </Group>
        </Container>
        <TableSlackIntegrations
          slackFullRecord={instanceActivityRecord}
          filterConditions={filterConditions}
          searchCondition={searchCondition}
          activePage={activePage}
          setActivePage={setActivePage}
        />
      </Paper>
    </>
  );
};

export default ScreenSlackInstance;
