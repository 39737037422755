import {
  Avatar,
  Badge,
  Center,
  Checkbox,
  Container,
  createStyles,
  Divider,
  Group,
  HoverCard,
  Loader,
  MantineTheme,
  Stack,
  Table,
  Text,
  ThemeIcon,
  Tooltip,
  UnstyledButton,
} from "@mantine/core";
import { Link } from "react-router-dom";
import {
  SalesforceAppMenuItemRecord,
  SalesforceIntegrationsAndActivityRecord,
} from "../../api/generated";
import { SfIntegrationLogo } from "../../misc/toolkit_salesforce";

import {
  IconAlertTriangle,
  IconChevronDown,
  IconChevronUp,
  IconInfoCircle,
  IconSelector,
} from "@tabler/icons";
import { format, parse } from "fecha";
import { useState } from "react";
import {
  DISPLAY_DATETIME_FORMAT,
  VANTYR_API_DATETIME_FORMAT,
} from "../../constants/constants";
import { colorAvatar } from "../../misc/mixins_mantine";
import { formatBytes } from "../../misc/toolkit_common";
import { usePaginationStore } from "../../state/store";
import PaginationControl from "../common/PaginationControl";
import { demoMode, inflateSfUsers } from "../../tooling/demo";

const badgeColorByRisk = ["gray.6", "orange.6", "red.6", "pink.6"];
const badgeLabelByRisk = ["Low", "Medium", "High", "Urgent"];

const useStyles = createStyles((theme) => ({
  rowSelected: {
    backgroundColor:
      theme.colorScheme === "dark"
        ? theme.fn.rgba(theme.colors[theme.primaryColor][7], 0.2)
        : theme.colors[theme.primaryColor][0],
  },

  tableHeader: {
    backgroundColor:
      theme.colorScheme === "dark"
        ? theme.fn.rgba(theme.colors["gray"][8], 0.2)
        : theme.colors["gray"][0],
  },

  th: {
    padding: "0 !important",
  },

  control: {
    width: "100%",
    padding: `${theme.spacing.xs} ${theme.spacing.md}`,

    "&:hover": {
      backgroundColor:
        theme.colorScheme === "dark"
          ? theme.colors.dark[6]
          : theme.colors.gray[0],
    },
  },

  icon: {
    width: 21,
    height: 21,
  },
}));

interface ThProps {
  children: React.ReactNode;
  reversed: boolean;
  sorted: boolean;
  style?: React.CSSProperties;
  description?: React.ReactNode;
  onSort(): void;
  position?: "left" | "right" | "center" | "apart";
  pl?: number;
  pr?: number;
}

function Th({
  children,
  reversed,
  sorted,
  onSort,
  style,
  description,
  position = "left",
  pl = 10,
  pr = 10,
}: ThProps) {
  const { classes } = useStyles();
  const Icon = sorted
    ? reversed
      ? IconChevronUp
      : IconChevronDown
    : IconSelector;
  return (
    <th style={style} className={classes.th}>
      <UnstyledButton
        onClick={onSort}
        className={classes.control}
        pl={pl}
        pr={pr}
      >
        <Group spacing={4} position="apart">
          <Group spacing={4}>
            <Text
              weight={500}
              size="xs"
              style={{ textOverflow: "ellipsis", overflow: "hidden" }}
              lineClamp={1}
            >
              {children}
            </Text>
            {description && (
              <HoverCard
                width={360}
                shadow="xl"
                withArrow
                openDelay={100}
                closeDelay={100}
                position="bottom"
                offset={20}
              >
                <HoverCard.Target>
                  <Center className={classes.icon}>
                    <IconInfoCircle size={14} stroke={1} />
                  </Center>
                </HoverCard.Target>
                <HoverCard.Dropdown>{description}</HoverCard.Dropdown>
              </HoverCard>
            )}
          </Group>
          <Center className={classes.icon}>
            <Icon size={14} stroke={1} />
          </Center>
        </Group>
      </UnstyledButton>
    </th>
  );
}

const cellApproved = (
  integration: SalesforceAppMenuItemRecord,
  instanceId: string,
  theme: MantineTheme,
  align: "left" | "right"
) => {
  return !integration.createdOn ? (
    <Badge
      color="pink.6"
      size={align === "right" ? "md" : "sm"}
      radius="xl"
      component={Link}
      to={`/applications/salesforce/${instanceId}/integrations/${integration.id}?t=scopes`}
      style={{ cursor: "pointer" }}
    >
      Not approved
    </Badge>
  ) : (
    align === "right" && (
      <Group position={align}>
        <Text
          color={
            integration.vantyrStatus === "Removed"
              ? "dimmed"
              : theme.colorScheme === "dark"
              ? "gray.4"
              : "gray.8"
          }
        >
          {format(
            parse(integration.createdOn!, VANTYR_API_DATETIME_FORMAT)!,
            DISPLAY_DATETIME_FORMAT
          )}
        </Text>
        <Tooltip
          label={integration.createdBy}
          withArrow
          color={theme.colors.dark[3]}
          transitionProps={{
            transition: "fade",
          }}
        >
          <Avatar
            radius="xl"
            size="sm"
            p={0}
            m={0}
            {...colorAvatar(
              integration.createdBy || " ",
              integration.vantyrStatus === "Removed"
            )}
          />
        </Tooltip>
      </Group>
    )
  );
};

interface TableSalesforceIntegrationsProps {
  sfFullRecord: SalesforceIntegrationsAndActivityRecord;
  filterConditions: string[];
  searchCondition: string;
  activePage: number;
  setActivePage: (page: number) => void;
  selection: string[];
  setSelection: React.Dispatch<React.SetStateAction<string[]>>;
  narrowView?: boolean;
}

export function TableSalesforceIntegrations({
  sfFullRecord,
  filterConditions,
  searchCondition,
  activePage,
  setActivePage,
  selection,
  setSelection,
  narrowView = false,
}: TableSalesforceIntegrationsProps) {
  const { classes, cx, theme } = useStyles();

  const toggleRow = (id: string) =>
    setSelection((current) =>
      current.includes(id)
        ? current.filter((item) => item !== id)
        : [...current, id]
    );

  const toggleAll = (data: string[]) =>
    setSelection((current) => (current.length === data.length ? [] : data));

  // const [allSelected, setAllSelected] = useState(false);

  const [sortBy, setSortBy] = useState<
    keyof SalesforceAppMenuItemRecord | null
  >(null);
  const [reverseSortDirection, setReverseSortDirection] = useState(false);

  const setSorting = (field: keyof SalesforceAppMenuItemRecord) => {
    const reversed = field === sortBy ? !reverseSortDirection : false;
    setReverseSortDirection(reversed);
    setSortBy(field);
  };

  const { pageSize, setPageSize } = usePaginationStore.get(
    "instance-integrations"
  )!((state) => ({
    pageSize: state.pageSize,
    setPageSize: state.setPageSize,
  }));

  const integrations = sfFullRecord.integrations!;
  const instanceId = sfFullRecord.instance?.instanceId;

  const rows = integrations.filter((integration) => {
    let searchOk = true;
    if (searchCondition) {
      searchOk =
        integration.label
          ?.toLowerCase()
          .includes(searchCondition.toLowerCase()) || false;
    }

    const integrationUsage =
      integration.usageVolumeBytes && integration.usageVolumeBytes > 0
        ? integration.classifiedFields!.filter(
            (f: string) =>
              f.includes("::PII") ||
              f.includes("::Sensitive") ||
              f.includes("::PCI") ||
              f.includes("::PHI")
          ).length > 0
          ? "usage-classified"
          : "usage-unclassified"
        : "usage-none";

    const integrationActive =
      integration.oauthTokens!.filter((x) => x.userIsActive).length !== 0
        ? "sessions-active"
        : "sessions-inactive";

    const integrationShadow = !integration.createdBy
      ? "shadow-yes"
      : "shadow-no";

    if (
      filterConditions.length === 0 ||
      (filterConditions.length !== 0 &&
        (!filterConditions.find((c) => c.includes("risk-")) ||
          filterConditions.includes(`risk-${integration.riskLevel}`)) &&
        (!filterConditions.find((c) => c.includes("usage-")) ||
          filterConditions.includes(integrationUsage)) &&
        (!filterConditions.find((c) => c.includes("sessions-")) ||
          filterConditions.includes(integrationActive)) &&
        (!filterConditions.find((c) => c.includes("shadow-")) ||
          filterConditions.includes(integrationShadow)))
    ) {
      return searchOk;
    } else {
      return false;
    }
  });

  const rowsFilteredLength = rows.length;

  const pageSizeAdjusted = pageSize !== 0 ? pageSize! : rowsFilteredLength;

  const rowsToDisplay = rows
    .sort((a, b) => {
      if (!sortBy) {
        return (
          (b.riskScore! || 0) -
          (a.riskScore! || 0) * (reverseSortDirection ? -1 : 1)
        );
      }

      // This is hacky and wacky, to be replaced with less funky code when API changes
      const bClassifiedFields =
        (b.classifiedFields?.find((f) => {
          return f.includes("::Sensitive");
        })
          ? 2
          : 0) +
        (b.classifiedFields?.find((f) => {
          return f.includes("::PII");
        })
          ? 2
          : 0) +
        (b.classifiedFields?.find((f) => {
          return f.includes("::PCI");
        })
          ? 2
          : 0) +
        (b.classifiedFields?.find((f) => {
          return f.includes("::PHI");
        })
          ? 2
          : 0) +
        (b.usageVolumeBytes! > 0 ? 1 : 0);

      const aClassifiedFields =
        (a.classifiedFields?.find((f) => {
          return f.includes("::Sensitive");
        })
          ? 2
          : 0) +
        (a.classifiedFields?.find((f) => {
          return f.includes("::PII");
        })
          ? 2
          : 0) +
        (a.classifiedFields?.find((f) => {
          return f.includes("::PHI");
        })
          ? 2
          : 0) +
        (a.classifiedFields?.find((f) => {
          return f.includes("::PCI");
        })
          ? 2
          : 0) +
        (a.usageVolumeBytes! > 0 ? 1 : 0);

      let aUsers =
        [
          ...new Set(
            a.oauthTokens!.filter((t) => t.userIsActive).map((t) => t.userName)
          ),
        ].length || 0;

      let bUsers =
        [
          ...new Set(
            b.oauthTokens!.filter((t) => t.userIsActive).map((t) => t.userName)
          ),
        ].length || 0;

      if (demoMode) {
        aUsers = inflateSfUsers(aUsers);
        bUsers = inflateSfUsers(bUsers);
      }

      switch (sortBy) {
        case "label":
          return (
            b.label!.localeCompare(a.label!, "en") *
            (reverseSortDirection ? -1 : 1)
          );
        case "riskLevel":
          return (
            ((b.riskScore! || 0) - (a.riskScore! || 0)) *
            (reverseSortDirection ? -1 : 1)
          );
        case "usageVolumeBytes":
          return (
            ((b.usageVolumeBytes! || 0) - (a.usageVolumeBytes! || 0)) *
            (reverseSortDirection ? -1 : 1)
          );
        case "oauthTokens":
          return (
            ((b.oauthTokens!.length || 0) - (a.oauthTokens!.length || 0)) *
            (reverseSortDirection ? -1 : 1)
          );
        case "classifiedFields":
          return (
            ((bClassifiedFields || 0) - (aClassifiedFields || 0)) *
            (reverseSortDirection ? -1 : 1)
          );
        case "oauthScopes": // this key is a hack to sort by the number of active users, TODO: fix this
          return (aUsers > bUsers ? 1 : -1) * (reverseSortDirection ? -1 : 1);
        case "createdBy":
          return (
            (b.createdBy! || "").localeCompare(a.createdBy! || "") *
            (reverseSortDirection ? -1 : 1)
          );
        case "createdOn":
          return (
            (a.createdOn &&
              b.createdOn &&
              (parse(b.createdOn, VANTYR_API_DATETIME_FORMAT)!.getTime() -
                parse(a.createdOn, VANTYR_API_DATETIME_FORMAT)!.getTime()) *
                (reverseSortDirection ? -1 : 1)) ||
            (!a.createdOn ? 1 : -1) * (reverseSortDirection ? -1 : 1)
          );
        case "exposedFieldsCountClassifiedTotal":
          return (
            ((b.exposedFieldsCountClassifiedTotal || 0) -
              (a.exposedFieldsCountClassifiedTotal || 0)) *
            (reverseSortDirection ? -1 : 1)
          );

        default:
          return (
            (b.riskLevel! || 0) -
            (a.riskLevel! || 0) * (reverseSortDirection ? -1 : 1)
          );
      }
    })
    .slice((activePage - 1) * pageSizeAdjusted, activePage * pageSizeAdjusted)
    .map((integration) => {
      let activeUsersCount = [
        ...new Set(
          integration
            .oauthTokens!.filter((t) => t.userIsActive)
            .map((t) => t.userName)
        ),
      ].length;

      if (demoMode) {
        activeUsersCount = inflateSfUsers(activeUsersCount);
      }

      const selected = selection.includes(integration.id!);

      const colorPostfix = integration.vantyrStatus === "Removed" ? ".3" : "";

      return (
        <tr
          key={integration.id}
          style={{ height: "60px" }}
          className={cx({ [classes.rowSelected]: selected })}
        >
          {!narrowView && (
            <td style={{ textAlign: "left", paddingLeft: 20 }}>
              <Checkbox
                size="xs"
                checked={selection.includes(integration.id!)}
                onChange={() => {
                  toggleRow(integration.id!);
                }}
                transitionDuration={0}
              />
            </td>
          )}
          <td style={{ textAlign: "left", paddingLeft: 20 }}>
            <UnstyledButton
              component={Link}
              to={`/applications/salesforce/${instanceId}/integrations/${integration.id}`}
            >
              <Group spacing="xs">
                <SfIntegrationLogo
                  integration={integration!}
                  width={24}
                  height={24}
                />
                <div style={{ width: "calc(100% - 40px)" }}>
                  <Stack spacing={0}>
                    <Text
                      ml="xs"
                      size="xs"
                      style={{ textOverflow: "ellipsis", overflow: "hidden" }}
                      lineClamp={2}
                      weight={600}
                      color={
                        integration.vantyrStatus === "Removed"
                          ? "dimmed"
                          : // : integration.isInvisible
                            // ? "red"
                            "default"
                      }
                    >
                      {integration.label}
                    </Text>
                    {narrowView && (
                      <Container style={{ width: "100%" }} pl="xs">
                        {cellApproved(integration, instanceId!, theme, "left")}
                      </Container>
                    )}
                  </Stack>
                  {integration.vantyrStatus === "Removed" && (
                    <Text ml="xs" size="xs" color="dimmed">
                      Removed via Vantyr
                    </Text>
                  )}
                  {false &&
                    integration.isInvisible &&
                    integration.vantyrStatus !== "Removed" && (
                      <Group>
                        <Text ml="xs" size="xs" color="red">
                          App is invisible to Salesforce
                        </Text>
                        <Tooltip
                          label="This app cannot be installed currently. It's active sessions can be removed only via Vantyr."
                          withArrow
                          color={theme.colors.dark[3]}
                          transitionProps={{
                            transition: "fade",
                          }}
                        >
                          <ThemeIcon
                            radius="xl"
                            size="xs"
                            p={0}
                            m={0}
                            variant="light"
                            color="red"
                          >
                            <IconAlertTriangle stroke={1} />
                          </ThemeIcon>
                        </Tooltip>
                      </Group>
                    )}
                </div>
              </Group>
            </UnstyledButton>
          </td>
          <td style={{ textAlign: "left", paddingLeft: 10 }}>
            <Tooltip
              label=<Stack>
                {integration.riskLevelReason
                  ?.split(".")
                  .map((r) => r && <Text key={r}>{r}.</Text>)}
                {integration.riskScore! >= 10 && (
                  <Text>
                    Risk score:{" "}
                    <strong>
                      {Math.max(0, Math.min(100, integration.riskScore!))}
                    </strong>
                  </Text>
                )}
              </Stack>
              withArrow
              multiline
              width={300}
              color={theme.colors.dark[3]}
              transitionProps={{
                transition: "fade",
              }}
            >
              {!integration.isUsageCalculated ||
              !integration.isUsageCalculated ? (
                <Badge variant="light" size="md" color="gray.6" radius="xl">
                  <Loader variant="dots" color="gray.6" size="xs"></Loader>
                </Badge>
              ) : (
                <Badge
                  variant="light"
                  size={narrowView ? "sm" : "md"}
                  color={
                    integration.label === "Vantyr"
                      ? "gray.6"
                      : integration.vantyrStatus === "Removed"
                      ? "blue.3"
                      : badgeColorByRisk[integration.riskLevel!]
                  }
                  radius="xl"
                  component={Link}
                  to={
                    !integration.createdBy
                      ? `/applications/salesforce/${instanceId}/integrations/${integration.name}?t=scopes`
                      : `/applications/salesforce/${instanceId}/integrations/${integration.id}?t=scopes`
                  }
                  style={{ cursor: "pointer" }}
                >
                  {integration.label === "Vantyr"
                    ? "N/A"
                    : integration.vantyrStatus === "Removed"
                    ? "Removed"
                    : integration.isZombie ||
                      !integration.oauthTokens ||
                      integration.oauthTokens.length === 0
                    ? "Inactive"
                    : badgeLabelByRisk[integration.riskLevel!]}
                </Badge>
              )}
            </Tooltip>
          </td>

          <td style={{ textAlign: "left", paddingLeft: 10, paddingRight: 20 }}>
            {!integration.isExposureCalculated ? (
              <Loader variant="dots" color="gray.6" size="xs"></Loader>
            ) : integration.oauthTokens!.filter((x) => x.userIsActive)
                .length !== 0 ? (
              <Text
                size="xs"
                weight={600}
                component={Link}
                to={`/applications/salesforce/${instanceId}/integrations/${integration.id}?t=data-exposure`}
                style={{ cursor: "pointer", width: 60 }}
                color={
                  integration.vantyrStatus === "Removed"
                    ? "dimmed"
                    : theme.colorScheme === "dark"
                    ? "gray.4"
                    : "gray.8"
                }
              >
                {integration.exposedFieldsCountClassifiedTotal?.toLocaleString()}
              </Text>
            ) : (
              <Text
                color={
                  integration.vantyrStatus === "Removed"
                    ? "dimmed"
                    : theme.colorScheme === "dark"
                    ? "gray.4"
                    : "gray.8"
                }
              >
                -
              </Text>
            )}
          </td>

          <td style={{ textAlign: "left", paddingLeft: 10, paddingRight: 20 }}>
            {!integration.isUsageCalculated ? (
              <Loader variant="dots" color="gray.6" size="xs"></Loader>
            ) : integration.usageVolumeBytes! > 0 ? (
              <Text
                size="xs"
                weight={600}
                component={Link}
                to={`/applications/salesforce/${instanceId}/integrations/${integration.id}?t=data-movement`}
                style={{ cursor: "pointer", width: 60 }}
                color={
                  integration.vantyrStatus === "Removed"
                    ? "dimmed"
                    : theme.colorScheme === "dark"
                    ? "gray.4"
                    : "gray.8"
                }
              >
                {formatBytes(integration.usageVolumeBytes!)}
              </Text>
            ) : (
              <Text
                color={
                  integration.vantyrStatus === "Removed"
                    ? "dimmed"
                    : theme.colorScheme === "dark"
                    ? "gray.4"
                    : "gray.8"
                }
              >
                -
              </Text>
            )}
          </td>

          {!narrowView && (
            <td
              style={{ textAlign: "left", paddingLeft: 10, paddingRight: 20 }}
            >
              {!integration.isUsageCalculated ? (
                <Loader variant="dots" color="gray" size="xs"></Loader>
              ) : integration.usageVolumeBytes! > 0 ? (
                <Group spacing={4}>
                  {integration.classifiedFields?.find((field) =>
                    field.includes("::PII")
                  ) && (
                    <Badge radius="xl" size="md" color={"pink" + colorPostfix}>
                      PII
                    </Badge>
                  )}
                  {integration.classifiedFields?.find((field) =>
                    field.includes("::PCI")
                  ) && (
                    <Badge radius="xl" size="md" color={"red" + colorPostfix}>
                      PCI
                    </Badge>
                  )}
                  {integration.classifiedFields?.find((field) =>
                    field.includes("::PHI")
                  ) && (
                    <Badge radius="xl" size="md" color={"green" + colorPostfix}>
                      PHI
                    </Badge>
                  )}
                  {integration.classifiedFields?.find((field) =>
                    field.includes("::Sensitive")
                  ) && (
                    <Badge radius="xl" size="md" color={"grape" + colorPostfix}>
                      Sensitive
                    </Badge>
                  )}
                  {!integration.classifiedFields?.some(
                    (field) => !field.includes("::Unclassified")
                  ) && (
                    <Text
                      color={
                        integration.vantyrStatus === "Removed"
                          ? "dimmed"
                          : theme.colorScheme === "dark"
                          ? "gray.4"
                          : "gray.8"
                      }
                    >
                      -
                    </Text>
                  )}
                </Group>
              ) : (
                <Text
                  color={
                    integration.vantyrStatus === "Removed"
                      ? "dimmed"
                      : theme.colorScheme === "dark"
                      ? "gray.4"
                      : "gray.8"
                  }
                >
                  -
                </Text>
              )}
            </td>
          )}

          <td style={{ textAlign: "left", paddingLeft: 10 }}>
            {!integration.isUsageCalculated ? (
              <Loader variant="dots" color="gray.6" size="xs"></Loader>
            ) : integration.oauthTokens!.length !== 0 ? (
              <Text
                size="xs"
                weight={600}
                component={Link}
                to={`/applications/salesforce/${instanceId}/integrations/${integration.id}?t=sessions`}
                style={{ cursor: "pointer", width: 20 }}
                color={
                  integration.vantyrStatus === "Removed"
                    ? "dimmed"
                    : theme.colorScheme === "dark"
                    ? "gray.4"
                    : "gray.8"
                }
              >
                {activeUsersCount}
              </Text>
            ) : (
              <Text
                color={
                  integration.vantyrStatus === "Removed"
                    ? "dimmed"
                    : theme.colorScheme === "dark"
                    ? "gray.4"
                    : "gray.8"
                }
              >
                -
              </Text>
            )}
          </td>

          {!narrowView && (
            <td align="right" style={{ paddingRight: 20 }}>
              {cellApproved(integration, instanceId!, theme, "right")}
            </td>
          )}
        </tr>
      );
    });

  return (
    <>
      <Table
        verticalSpacing="xs"
        horizontalSpacing={0}
        fontSize="xs"
        style={{ tableLayout: "fixed" }}
      >
        <thead>
          <tr className={cx({ [classes.tableHeader]: true })}>
            {!narrowView && (
              <th style={{ width: "40px", textAlign: "left", paddingLeft: 20 }}>
                <Checkbox
                  size="xs"
                  onChange={(v) => toggleAll(rows.map((x) => x.id!))}
                  checked={selection.length === rows.length}
                  indeterminate={
                    selection.length > 0 && selection.length !== rows.length
                  }
                  transitionDuration={0}
                />
              </th>
            )}
            <Th
              sorted={sortBy === "label"}
              reversed={reverseSortDirection}
              onSort={() => setSorting("label")}
              style={{ textAlign: "left", minWidth: 280 }}
              pl={20}
            >
              Integration
            </Th>
            <Th
              sorted={sortBy === "riskLevel"}
              reversed={reverseSortDirection}
              onSort={() => setSorting("riskLevel")}
              style={
                !narrowView
                  ? { textAlign: "left", width: 140 }
                  : { textAlign: "left", width: 95 }
              }
              description={
                <Text size="xs" weight={300}>
                  Vantyr’s risk score algorithm factors in the following
                  variables:
                  <br />
                  <ul style={{ listStyle: "inside", paddingLeft: 0 }}>
                    <li>permissions/scopes granted</li>
                    <li>real and potential data exposure</li>
                    <li>users, their roles, and access levels</li>
                    <li>frequency and nature of API calls made by the app</li>
                    <li>approval by a Salesforce admin</li>
                  </ul>
                </Text>
              }
            >
              Risk
            </Th>
            <Th
              sorted={sortBy === "exposedFieldsCountClassifiedTotal"}
              reversed={reverseSortDirection}
              onSort={() => setSorting("exposedFieldsCountClassifiedTotal")}
              style={
                !narrowView
                  ? { textAlign: "left", width: 160 }
                  : { textAlign: "left", width: 150 }
              }
              description={
                <Text size="xs" weight={300}>
                  Displays total count of unique instances of PII, PHI, PCI and
                  Business Sensitive data exposed to the integration.
                </Text>
              }
            >
              Data exposed
            </Th>
            <Th
              sorted={sortBy === "usageVolumeBytes"}
              reversed={reverseSortDirection}
              onSort={() => setSorting("usageVolumeBytes")}
              style={
                !narrowView
                  ? { textAlign: "left", width: 130 }
                  : { textAlign: "left", width: 100 }
              }
            >
              Data flow
            </Th>
            {!narrowView && (
              <Th
                sorted={sortBy === "classifiedFields"}
                reversed={reverseSortDirection}
                onSort={() => setSorting("classifiedFields")}
                style={{ textAlign: "left", width: 160 }}
              >
                Data types
              </Th>
            )}
            <Th
              sorted={sortBy === "oauthScopes"}
              reversed={reverseSortDirection}
              onSort={() => setSorting("oauthScopes")}
              style={
                !narrowView
                  ? { textAlign: "left", width: 100 }
                  : { textAlign: "left", width: 80 }
              }
            >
              Users
            </Th>
            {!narrowView && (
              <Th
                sorted={sortBy === "createdOn"}
                reversed={reverseSortDirection}
                onSort={() => setSorting("createdOn")}
                style={{ textAlign: "right", width: 140, paddingRight: 20 }}
                position="right"
                pr={20}
                description={
                  <Text size="xs" weight={300} align="left">
                    Displays the app date of approval and the Salesforce admin
                    that approved the integration.
                    <br />
                    <br />
                    “Not Approved” apps are shadow integrations that have{" "}
                    <strong>not</strong> been approved / installed by any admin.
                  </Text>
                }
              >
                Approved
              </Th>
            )}
          </tr>
        </thead>
        <tbody>{rowsToDisplay}</tbody>
      </Table>
      <Divider />
      <Container
        pl="xl"
        pr="sm"
        pt="lg"
        pb="lg"
        fluid
        style={{ width: "100%" }}
      >
        <PaginationControl
          activePage={activePage}
          setActivePage={setActivePage}
          pageSize={pageSize}
          setPageSize={setPageSize}
          rowsNumber={rowsFilteredLength}
        />
      </Container>
    </>
  );
}
