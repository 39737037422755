import {
  Center,
  Container,
  Group,
  Loader,
  Paper,
  Stack,
  Tabs,
  Text,
} from "@mantine/core";
import { useDebouncedState } from "@mantine/hooks";
import { IconActivityHeartbeat, IconLink } from "@tabler/icons";
import { useState } from "react";
import { useQuery } from "react-query";
import { useParams } from "react-router-dom";
import { SalesforceApi } from "../../api/generated";
import { useAppsStore, useConfigStore, useTabStore } from "../../state/store";
import { demoInstanceFullRecord, demoMode } from "../../tooling/demo";
import Oops from "../common/Oops";
import PillTabs, { PillTabConfig } from "../common/PillTabs";
import SearchInput from "../common/SearchInput";
import FilterSalesforceInstances from "../salesforce/FilterSalesforceInstances";
import {
  SalesforceInstanceSummary,
  SummarySalesforceIntegrations,
} from "../salesforce/SalesforceSummaryInstance";
import { TableSalesforceIntegrations } from "../salesforce/TableSalesforceIntegrations";
import ViewSalesforceDashboard from "../salesforce/ViewSalesforceDashboard";

const tabs = [
  { label: "Summary", value: "summary", icon: IconActivityHeartbeat },
  { label: "Integrations", value: "integrations", icon: IconLink },
  // { label: "Data exposure", value: "exposure", icon: IconPackages },
  // { label: "Data flow", value: "flow", icon: IconPackgeExport },
] as PillTabConfig[];

const ScreenSalesforceInstance = () => {
  const { instanceId } = useParams();

  const setInstanceSelected = useAppsStore(
    (state) => state.setInstanceSelected
  );

  // useQuery to fetch integrations
  const { isError, error, isLoading, data } = useQuery(
    `integrations_${instanceId}`,
    async () => {
      if (demoMode) {
        return demoInstanceFullRecord;
      }

      const rsp = await new SalesforceApi().listIntegrations(instanceId!, true);
      return rsp.data;
    },
    {
      staleTime: 10 * 60 * 1000,
      refetchInterval: 10 * 60 * 1000,
    }
  );

  const [filterConditions, setFilterConditions] = useState<string[]>([]);
  const [searchCondition, setSearchCondition] = useDebouncedState<string>(
    "",
    200
  );

  const [activePage, setActivePage] = useState(1);

  const [selection, setSelection] = useState([] as string[]);

  const { activeTab, setActiveTab } = useTabStore((state) => ({
    activeTab: state.salesforceInstanceTab,
    setActiveTab: state.setSalesforceInstanceTab,
  }));

  const toggleDataPullMode = useConfigStore(
    (state) => state.toggleDataPullMode
  );

  const setActiveTabIntegrations = (filterConditions?: string[]) => {
    setActiveTab("integrations");
    filterConditions && setFilterConditions(filterConditions);
    // FIXME: This is a easter-egg-like trick to toggle data pull mode
    if (
      filterConditions &&
      filterConditions.length === 1 &&
      filterConditions[0] === "risk-0"
    ) {
      toggleDataPullMode();
    }
  };

  const onSearch = (searchCondition: string) => {
    setActivePage(1);
    setSelection([]);
    setSearchCondition(searchCondition);
  };

  const onFilter = (filterConditions: string[]) => {
    setActivePage(1);
    setSelection([]);
    setFilterConditions(filterConditions);
  };

  // if loading
  if (isLoading) {
    return (
      <Center style={{ width: "100%", height: "100%" }}>
        <Stack align="center">
          <Text size="sm" my="md">
            Detecting integrations and activity. Hold on, this may take a minute
            ...
          </Text>
          <Loader color="gray" variant="dots"></Loader>
        </Stack>
      </Center>
    );
  }

  // if error
  if (isError || error) {
    return <Oops />;
  }

  setInstanceSelected(data?.instance || null);

  // if all OK
  console.log("Oauth Stats:");
  console.log(data?.oauthStats);

  return (
    <>
      <Paper shadow="xl" mx="xl" my="xs" style={{ width: "auto" }}>
        <Container fluid p="md">
          <Group position="apart" pl="md">
            <SalesforceInstanceSummary instance={data!.instance!} />
            <PillTabs
              tabs={tabs}
              activeTab={activeTab}
              setActiveTab={setActiveTab}
            ></PillTabs>
          </Group>
        </Container>
      </Paper>
      <Tabs value={activeTab}>
        <Tabs.Panel value="summary">
          <ViewSalesforceDashboard
            instanceRecord={data!}
            onIntegrationsClicked={(riskLevel) =>
              riskLevel === -1
                ? setActiveTabIntegrations()
                : setActiveTabIntegrations([`risk-${riskLevel}`])
            }
            onFilterSelected={setActiveTabIntegrations}
          />
        </Tabs.Panel>
        <Tabs.Panel value="integrations">
          <Paper
            shadow="xl"
            m="xl"
            style={{ minWidth: 1000, maxWidth: 1600, width: "auto" }}
          >
            <Container p="md" fluid>
              <Group position="apart">
                <SummarySalesforceIntegrations
                  fullRecord={data!}
                  selection={selection}
                />
                <Group position="right">
                  <SearchInput
                    onSearch={(searchCondition) => onSearch(searchCondition)}
                  />
                  <FilterSalesforceInstances
                    onFilter={(filterConditions) => onFilter(filterConditions)}
                    filterConditions={filterConditions}
                  />
                </Group>
              </Group>
            </Container>
            <TableSalesforceIntegrations
              sfFullRecord={data!}
              filterConditions={filterConditions}
              searchCondition={searchCondition}
              activePage={activePage}
              setActivePage={setActivePage}
              selection={selection}
              setSelection={setSelection}
            />
          </Paper>
        </Tabs.Panel>
        {/* <Tabs.Panel value="exposure">
          <Paper
            shadow="xl"
            mx="xl"
            my="xs"
            style={{ width: "auto" }}
          >
            <Container fluid p="md">
              <Group position="apart" pl="md">
                <Text size="lg">Data exposure</Text>
              </Group> */}
      </Tabs>
    </>
  );
};

export default ScreenSalesforceInstance;
