import {
  ActionIcon,
  Button,
  Center,
  Group,
  Image,
  Paper,
  ScrollArea,
  Space,
  Text,
  useMantineTheme,
} from "@mantine/core";
import { showNotification } from "@mantine/notifications";
import { IconArrowLeft } from "@tabler/icons";
import { useNavigate } from "react-router-dom";
import { AccessApi } from "../../api/generated";
import { useAccessStore } from "../../state/store";
import { ProductLogoAssets } from "../common/ProductLogoAssets";

export function ScreenTerms() {
  const signedIn = useAccessStore((state) => state.signedIn);
  const acceptTerms = useAccessStore((state) => state.acceptTerms);
  const signOut = useAccessStore((state) => state.signOut);

  const tryAcceptTerms = async () => {
    console.log("Accepting terms...");
    const accessApi = new AccessApi();
    accessApi.acceptTerms().then(
      (rsp) => {
        console.log("Terms accepted");
        acceptTerms();
        window.location.pathname = "/applications";
      },
      (err) => {
        console.log("Terms acceptance failed");
        showNotification({
          title: "Terms acceptance failed",
          message: "Please try again",
          color: "red",
        });
      }
    );
  };

  const logout = () => {
    window.location.pathname = "/";
    signOut();
  };

  const navigate = useNavigate();

  const theme = useMantineTheme();
  return (
    <Center>
      <Paper
        style={{ width: "1200px", height: "820px" }}
        p="xl"
        m="xl"
        shadow="xl"
      >
        {!signedIn ? (
          <Group position="apart">
            <ActionIcon onClick={() => navigate(-1)}>
              <IconArrowLeft stroke={1} />
            </ActionIcon>
            <Image
              src={ProductLogoAssets.get(`vantyr-${theme.colorScheme}`)}
              height={60}
              width="auto"
              fit="contain"
            />
            <Space w="xl" />
          </Group>
        ) : (
          <Center>
            <Image
              src={ProductLogoAssets.get(`vantyr-${theme.colorScheme}`)}
              height={60}
              width="auto"
              fit="contain"
            />
          </Center>
        )}
        <Space h="xl"></Space>
        <Text size={28} weight={600} pl="md">
          Terms of Service
        </Text>
        <Space h="xl"></Space>
        <ScrollArea style={{ height: "520px" }}>
          <Paper style={theme.colorScheme === "dark" ? { backgroundColor: "#141517"} : { backgroundColor: "#F8F9FA"}} p="xl">
            <Text size="md" weight={600}>
              1. Terms of Service
            </Text>
            <Space h="md"></Space>
            <Text size="sm">
              These Terms of Service (as defined below) are a legally binding
              agreement between Vantyr and you.
              <br />
              <br />
              The term “Vantyr ”, “us”, “we” or “our”, refers to Vantyr Inc.,
              the owner of the Service.
              <br />
              <br />
              The term “you” refers to the User (as defined below).
              <br />
              <br />
              These Terms of Service govern your access to the Site and use of
              the Service and the features contained therein, whether through
              personal computers, mobile devices, or any other devices supported
              by our Service.
            </Text>
            <Space h="xl"></Space>
            <Text size="md" weight={600}>
              2. Definitions
            </Text>
            <Space h="md"></Space>
            <Text size="sm">
              For the purposes hereof:
              <br />
              <br />
              <strong>“User Data”</strong> means all electronic data or
              information submitted by User and/or its Users to the Service;
              <br />
              <br />
              <strong>“Malicious Code”</strong> means viruses, worms, time
              bombs, Trojan horses and other harmful or malicious code, files,
              scripts, agents or programs;
              <br />
              <br />
              <strong>“Service”</strong> means Vantyr’s SaaS security platform
              which is a tool used to apply monitoring and observability
              capabilities to SaaS applications utilized by companies;
              <br />
              <br />
              <strong>“Site”</strong> means Vantyr’s internet site for the
              provision of the Service, found at https://www.vantyr.com;
              <br />
              <br />
              <strong>“Terms of Service”</strong> means these Terms of Service;
              <br />
              <br />
              <strong>“Billing Cycle”</strong> means the period between the
              beginning of the Subscription Plan and the last day of the last
              month of the Subscription Plan.
              <br />
              <br />
              <strong>“Term”</strong> means the period of time between the
              payment for the Subscription Plan and the next Billing Cycle.
              <br />
              <br />
              <strong>“User”</strong> means an individual or entity who is
              authorized by User to use the Service, and who has been supplied a
              user account and password by User (or by Vantyr at User’s request)
              for the Service means any person accessing the Site, or using the
              Service.
            </Text>
            <Space h="xl"></Space>
            <Text size="md" weight={600}>
              3. Intellectual Property
            </Text>
            <Space h="md"></Space>
            <Text size="sm">
              As between User and Vantyr, Vantyr retains ownership of the
              Services, the Vantyr Materials, and all intellectual property
              rights and other proprietary rights related to them. No licenses
              or rights are granted to User by Vantyr other than as expressly
              provided for in this Agreement.
            </Text>
            <Space h="xl"></Space>
            <Text size="md" weight={600}>
              4. Confidentiality
            </Text>
            <Space h="md"></Space>
            <Text size="sm">
              Definition of Confidential Information. As used herein,
              “Confidential Information” means all confidential and proprietary
              information of a party (the “Disclosing Party”) disclosed to the
              other party (the “Receiving Party”), whether orally or in writing,
              that is designated as confidential or that reasonably should be
              understood to be confidential given the nature of the information
              and the circumstances of disclosure, including the terms and
              conditions of this Agreement, the User Data, the Service, business
              and marketing plans, technology and technical information, product
              designs, and business processes. Confidential Information shall
              not include any information that: (i) is or becomes generally
              known to the public without breach of any obligation owed to the
              Disclosing Party; (ii) was known to the Receiving Party prior to
              its disclosure by the Disclosing Party without breach of any
              obligation owed to the Disclosing Party; (iii) was independently
              developed by the Receiving Party without breach of any obligation
              owed to the Disclosing Party; or (iv) is received from a third
              party without breach of any obligation owed to the Disclosing
              Party.
              <br />
              <br />
              Confidentiality. The Receiving Party shall not disclose or use any
              Confidential Information of the Disclosing Party for any purpose
              outside the scope of this Agreement, except with the Disclosing
              Party's prior written permission.
              <br />
              <br />
              Protection. Each party agrees to protect the confidentiality of
              the Confidential Information of the other party in the same manner
              that it protects the confidentiality of its own proprietary and
              confidential information of like kind (but in no event using less
              than reasonable care).
              <br />
              <br />
              Compelled Disclosure. If the Receiving Party is compelled by law
              to disclose Confidential Information of the Disclosing Party, it
              shall provide the Disclosing Party with prior notice of such
              compelled disclosure (to the extent legally permitted) and
              reasonable assistance, at the Disclosing Party's cost, if the
              Disclosing Party wishes to contest the disclosure.
            </Text>
            <Space h="xl"></Space>
            <Text size="md" weight={600}>
              5. Security and Processing of Personal Data
            </Text>
            <Space h="md"></Space>
            <Text size="sm">
              User Data. User owns all right, title and interest in all User
              Data. Nothing in this Agreement shall be construed to grant Vantyr
              any rights in User Data beyond those expressly provided herein.
              User grants Vantyr the limited, non-exclusive, worldwide license
              to view and use the User Data solely for the purpose of providing
              the SaaS Products. Vantyr may process aggregated and/or anonymized
              data to maintain and improve the Services and the technology used
              therefor.
              <br />
              <br />
              VANTYR SHALL USE REASONABLE EFFORTS TO PROTECT INFORMATION
              SUBMITTED BY USER IN CONNECTION WITH THE SERVICE, BUT USER HEREBY
              ACKNOWLEDGES AND AGREES THAT USER’S SUBMISSION OF SUCH INFORMATION
              IS AT USER’S SOLE RISK, AND VANTYR HEREBY DISCLAIMS ANY AND ALL
              LIABILITY FOR ANY DISCLOSURE, LOSS OR LIABILITY RELATING TO SUCH
              INFORMATION IN ANY WAY.
            </Text>
            <Space h="xl"></Space>
            <Text size="md" weight={600}>
              6. Warranties
            </Text>
            <Space h="md"></Space>
            <Text size="sm">
              User warrants that it has the legal power to enter into this
              Agreement. Vantyr warrants that (i) it will provide the Service in
              a manner consistent with general industry standards reasonably
              applicable to the provision thereof; (ii) the Service shall
              perform; and (iii) the Service will not contain or transmit to
              User any Malicious Code (except for any Malicious Code contained
              in User or User-uploaded materials or otherwise originating from
              User or a User).
              <br />
              <br />
              VANTYR MAKES NO REPRESENTATIONS AND PROVIDES NO WARRANTIES OR
              CONDITIONS OF ANY KIND, WHETHER EXPRESS, IMPLIED, STATUTORY OR
              OTHERWISE, AND SPECIFICALLY DISCLAIMS ALL IMPLIED REPRESENTATIONS,
              WARRANTIES AND/OR CONDITIONS, INCLUDING ANY REPRESENTATIONS,
              WARRANTIES AND/OR CONDITIONS OF MERCHANTABILITY, MERCHANTABLE
              QUALITY, DURABILITY, TITLE, NON-INFRINGEMENT, SATISFACTORY QUALITY
              OR FITNESS FOR A PARTICULAR PURPOSE, TO THE MAXIMUM EXTENT
              PERMITTED BY APPLICABLE LAW.
            </Text>
            <Space h="xl"></Space>
            <Text size="md" weight={600}>
              7. Term and Termination
            </Text>
            <Space h="md"></Space>
            <Text size="sm">
              The term of this Agreement shall remain in effect until terminated
              by either party. Either party may terminate this Agreement for
              convenience upon written notice to the other party. Upon
              expiration or termination of this Agreement, the User shall cease
              all use of the Trial Services. Either party may terminate this
              Agreement upon seven (7) days written notice.
              <br />
              <br />
              If User receives a free trial of the Services (including
              participating in a free proof of concept), User may only use those
              Services for the duration of that trial for the purpose of
              evaluating the desirability of purchasing the Services. Unless
              otherwise specified, either party may cancel a free trial before
              it ends by providing written notice to the other.
              <br />
              <br />
              Vantyr may terminate any services under a free trial plan with or
              without notice to User at any time, for any reason, including due
              to account inactivity.
              <br />
              <br />
              In all cases, the provisions which by their nature would continue
              beyond termination, including, without limitation, the provisions
              of Sections 1, 4, 6, 8, and 9, shall survive such termination for
              any reason and shall continue to apply.
            </Text>
            <Space h="xl"></Space>
            <Text size="md" weight={600}>
              8. Limitations Of Liability
            </Text>
            <Space h="md"></Space>
            <Text size="sm">
              IN NO EVENT SHALL EITHER PARTY'S AGGREGATE LIABILITY ARISING OUT
              OF OR RELATED TO THIS AGREEMENT, WHETHER IN CONTRACT, TORT
              (INCLUDING NEGLIGENCE) OR UNDER ANY OTHER THEORY OF LIABILITY,
              EXCEED THE AMOUNTS ACTUALLY PAID BY AND DUE FROM USER HEREUNDER IN
              THE TWELVE MONTHS PRECEDING THE INCIDENT GIVING RISE TO LIABILITY.
              <br />
              <br />
              Free Trial. Notwithstanding anything to the contrary in this
              Agreement, any liability of Vantyr to User relating to any
              Services provided during a free trial period, shall be limited to
              US$100.
              <br />
              <br />
              Exclusion of Consequential and Related Damages. IN NO EVENT SHALL
              EITHER PARTY HAVE ANY LIABILITY TO THE OTHER PARTY FOR ANY LOST
              PROFITS OR FOR ANY INDIRECT, SPECIAL, INCIDENTAL, PUNITIVE, OR
              CONSEQUENTIAL DAMAGES (INCLUDING, WITHOUT LIMITATION, DAMAGES FOR
              LOSS OF BUSINESS, LOSS OF PROFITS, BUSINESS INTERRUPTION, LOSS OF
              DATA, LOST SAVINGS OR OTHER SIMILAR PECUNIARY LOSS) HOWEVER CAUSED
              AND, WHETHER IN CONTRACT, TORT (INCLUDING NEGLIGENCE) OR UNDER ANY
              OTHER THEORY OF LIABILITY, WHETHER OR NOT THE PARTY HAS BEEN
              ADVISED OF THE POSSIBILITY OF SUCH DAMAGES.
            </Text>
            <Space h="xl"></Space>
            <Text size="md" weight={600}>
              9. Indemnification
            </Text>
            <Space h="md"></Space>
            <Text size="sm">
              You shall indemnify and hold Vantyr and its directors, officers,
              employees, agents, partners and licensors, harmless from and
              against all damages, losses, and expenses of any kind (including
              reasonable legal fees and costs), related to any demand or claim
              brought against Vantyr by any Users and/or any other third party,
              due to or arising out of any User Data you add on the Site and/or
              through the use of the Service, your use of the Site and/or the
              Service, your violation of these Terms of Service, and/or your
              violation of any rights of another party.
            </Text>
            <Space h="xl"></Space>
            <Text size="md" weight={600}>
              10. Governing Law and Jurisdiction
            </Text>
            <Space h="md"></Space>
            <Text size="sm">
              This Agreement shall be governed by and construed according to the
              laws of the State of Delaware without regards to conflicts of laws
              rules. If any provision of this Agreement is found by a court of
              competent jurisdiction to be unenforceable, that provision shall
              be severed and the remainder of this Agreement shall continue in
              full force and effect.
              <br />
              <br />
              Both parties hereby irrevocably submit to the exclusive
              jurisdiction of the competent courts in the State of Delaware to
              adjudicate any dispute arising out of or relating to these Terms
              of Service. Each party hereby consents to the exclusive
              jurisdiction of such courts.
            </Text>
            <Space h="xl"></Space>
            <Text size="md" weight={600}>
              11. Export
            </Text>
            <Space h="md"></Space>
            <Text size="sm">
              User acknowledges and agrees that the Service may be subject to
              export and import controls under the regulations of the United
              States, Canada, and other countries, and User shall comply with
              all export and import control regulations of such countries. User
              shall not use the Service for any purposes prohibited by export
              laws, including, without limitation, nuclear, chemical or
              biological weapons proliferation. User shall be responsible for
              procuring all required permissions for any subsequent export,
              import or use of the Service.
            </Text>
          </Paper>
        </ScrollArea>
        <Space h="md"></Space>
        {signedIn && (
          <>
            <Space h="xl"></Space>
            <Group position="right">
              <Button
                variant="outline"
                onClick={logout}
                style={{ width: "160px" }}
              >
                Back
              </Button>
              <Button onClick={tryAcceptTerms} style={{ width: "160px" }}>
                Accept
              </Button>
            </Group>
          </>
        )}
        <Space h="xs"></Space>
      </Paper>
    </Center>
  );
}
